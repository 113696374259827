import React from 'react';
import { StatsDetailHeader } from '../.././../statistics/stats-components'
import { setTTL } from '../../../business/misc-functions';
import { getFormalizationDocs } from '../../../services/api/stats';
import { FzTable } from '../../fz/grid/table';
import { FzCard } from '../../fz/layout/index';
import { PERIOD_DAY_WEEK_MONTH } from '../../../api/constants';
import { FzNavSelect } from '../../fz/nav';



class FormalizationDocsStats extends React.Component {
  constructor(props) {
    super(props)

    this.state = { requestState: "idle" };
    this.getStatistics = this.getStatistics.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
  }

  getStatistics(period) {
    const { ns } = this.props
    this.setState({ requestState: "requesting" }, () => {
      this.props.toggleBlocking(() => {
        getFormalizationDocs(period)
          .then((data) => {
            ns.set("formalization-docs", data, setTTL(5))
            this.setState({ requestState: "idle" }, () => this.props.toggleBlocking());
          })
          .catch((err) => {
            console.warn("API ERROR", err)
            this.props.toggleBlocking()
          })
      })
    });
  }

  handleSelect(periodo) {
    this.setState({ currentPeriodo: periodo }, () => this.getStatistics(this.state.currentPeriodo))
  }

  render() {
    const { ns, metas } = this.props;

    let data = ns.get("formalization-docs", {});
    let { requestState } = this.state;

    if (!data && requestState === "requesting") {
      return null
    }

    return (
      <FzCard>
        <FzCard.Heading>
          <FzCard.Title>
            <StatsDetailHeader stats={ns.get("formalization-docs")} />
          </FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FzNavSelect
            selected={this.state.currentPeriodo}
            onChange={this.handleSelect}
            options={[
              { "code": "day", "description": "Daily" },
              { "code": "week", "description": "Weekly" },
              { "code": "month", "description": "Monthly" },
            ]}
          />
        </FzCard.Body>
        <FzCard.Body>
          <FzTable
            data={data.statistics}
            ns={ns}
            metas={metas}
            exportDownload
            clipboard
          />
        </FzCard.Body>
      </FzCard>
    )
  }
}

export { FormalizationDocsStats };