import React from 'react'
import { FzTable } from '../../fz/grid/table'
import { setTTL } from '../../../business/misc-functions';
import { getFinanvcEvents } from '../../../services/api/stats'
import { FzNavSelect } from '../../fz/nav';
import { PERIOD_DAY_WEEK_MONTH } from '../../../api/constants';
import { FzCard } from '../../fz/layout/index';

class Finanvc extends React.Component {

  constructor(props) {
    super(props);
    this.state = { requestState: "idle" };
    this.handleSelect = this.handleSelect.bind(this)
    this.getStatistics = this.getStatistics.bind(this)
  }

  getStatistics(period) {
    const { ns } = this.props;
    this.setState({ requestState: "requesting" }, () => {
      this.props.toggleBlocking(() => {
        getFinanvcEvents(period)
          .then((json) => {
            ns.set("finanvc", json, setTTL(5));
            this.setState({ requestState: "idle" }, () => this.props.toggleBlocking());
          })
          .catch((err) => {
            console.warn("API ERROR", err);
            this.props.toggleBlocking()
          });
      })
    });
  }


  handleSelect(periodo) {
    this.setState({ currentPeriodo: periodo }, () => this.getStatistics(this.state.currentPeriodo))
  }

  render() {
    const { ns, metas } = this.props;

    let data = ns.get("finanvc", {});
    let { requestState } = this.state;

    if (!data && requestState === "requesting") {
      return null
    }

    return (
      <FzCard>
        <FzCard.Body>
          <FzNavSelect
            selected={this.state.currentPeriodo}
            onChange={this.handleSelect}
            options={[
              { "code": "day", "description": "Daily" },
              { "code": "week", "description": "Weekly" },
              { "code": "month", "description": "Monthly" },
            ]}
          />
          <FzCard.Body>
            <FzTable
              pagination
              ns={ns}
              context="statsMessagingFinanvc"
              data={data.statistics}
              metas={metas}
              visibleColumns={["key", "period", "event", "creations", "reads", "channels.email", "channels.sms", "channels.web"]}
            />
          </FzCard.Body>
        </FzCard.Body>
      </FzCard>
    );
  }
}

export { Finanvc }

