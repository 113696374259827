import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import api from './api/client-api';
import { getLogoutURL } from './api/helpers';
import GAuth from './ui/components/g-auth';
import { FzCard, FzRow, FzCol } from './ui/fz/layout/index';
import { FzField } from './ui/fz/form';
import { FzAlert } from './ui/fz/form/alert';
import { FzIcons } from './ui/fz/form/icon';
import { Accordion } from 'react-bootstrap'

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = { errorMessage: null, userEmail: "", userCode: "", userPassword: "", codeRequested: false };
    this.apiError = this.apiError.bind(this);
    this.isEmailValid = this.isEmailValid.bind(this);
    this.sucessfullLogin = this.sucessfullLogin.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.loginWithCode = this.loginWithCode.bind(this);
    this.loginWithPassword = this.loginWithPassword.bind(this);
    this.requestNewCode = this.requestNewCode.bind(this);
    this.receiveNewKeyCode = this.receiveNewKeyCode.bind(this);
    this.reloadPage = this.reloadPage.bind(this)
  }
  apiError(returnCode) {
    if (returnCode === null || returnCode !== 400) {
      this.setState({ errorMessage: "Nosso servidor está temporariamente indisponível. Tente novamente em instantes." });
    } else {
      this.setState({ errorMessage: "Usuário ou senha inválidos. Por favor verifique e tente novamente." });

    }
  }
  isEmailValid() {
    if (this.state.userEmail.length === 0) {
      return true
    }
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(this.state.userEmail)
  }

  sucessfullLogin() {
    const { location, history } = this.props

    let nextPathname
    if (location?.search?.includes("nextPathname")) {
      nextPathname = location.search.replace("?", "").replace("nextPathname=", "")
    }

    if (nextPathname) {
      history.push(nextPathname)
    } else if (location?.state?.nextPathname) {
      history.push(location.state.nextPathname)
    } else {
      history.push('/')
    }

    this.reloadPage()

  }

  reloadPage() {
    window.setTimeout(window.location.reload(false), 2000);
  }

  handlePasswordChange(userPassword) {
    this.setState({ userPassword })
  }
  handleEmailChange(userEmail) {
    this.setState({ userEmail })
  }

  handleCodeChange(userCode) {
    this.setState({ userCode })
  }

  loginWithCode() {
    this.setState({ errorMessage: null });
    api.loginWithCode(this.state.userKey, this.state.userCode, this.sucessfullLogin, this.apiError);

  }

  loginWithPassword() {
    if (this.state.userEmail && this.state.userPassword) {
      this.setState({ errorMessage: null });
      api.loginWithPassword(this.state.userEmail, this.state.userPassword, this.sucessfullLogin, this.apiError);
    }
  }

  requestNewCode() {
    if (this.isEmailValid() && this.state.userEmail.length > 5 && !this.state.codeRequested) {
      this.setState({ errorMessage: null, codeRequested: true });
      api.requestNewCode(this.state.userEmail, this.receiveNewKeyCode);
    }
  }
  receiveNewKeyCode(newKey, err) {
    if (err) {
      this.setState({ errorMessage: `Erro ao solicitar código de accesso: ${err.error}` })
    } else {
      this.setState({ userKey: newKey })
    }
  }

  render() {
    return (
      <FzCard id="LoginMain">
        <FzCard.Heading>
          <FzCard.Title>Utilize suas credenciais FinanZero </FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          {this.state.errorMessage != null ? <FzAlert title={"Erro de Login"} fzStyle={"attention"}>{this.state.errorMessage}</FzAlert> : null}
          <FzRow>
            <FzCol span={4}></FzCol>
            <FzCol span={4}>
              <div style={{ margin: "0 auto", width: "max-content", maxWidth: "100%"}}>
                <GAuth />
                <Accordion >
                  <div className={"fz-radio-container"} >
                    <Accordion.Toggle
                      as={Form.Check}
                      type="switch"
                      id={'other_login'}
                      eventKey="other_login"
                    />
                    <span>
                      Utilizar outro email
                    </span>
                  </div>

                  <Accordion.Collapse eventKey="other_login">
                    <FzCard>
                      <FzCard.Body>
                        <FzField
                          name="email"
                          dataType={"string"}
                          label={"Digite seu Email"}
                          inputType="text"
                          fzStyle="small"
                          onChange={this.handleEmailChange}
                          placeHolder="Email"
                          invalid={!this.isEmailValid()}
                          value={this.state.userEmail}
                        />
                        <FzRow>
                          <FzCol span={12}>
                            <Accordion>
                              <div className={"fz-radio-container"} >
                                <Accordion.Toggle
                                  as={Form.Check}
                                  type="switch"
                                  id={'other_login_request_code'}
                                  eventKey="other_login_request_code"
                                  onClick={this.requestNewCode}
                                />
                                <span>
                                  <label htmlFor={`auth-types-code`}>Login com código {this.state.codeRequested ? "(Código solicitado)" : ""}</label>
                                </span>
                              </div>

                              <Accordion.Collapse eventKey="other_login_request_code">
                                <FzCard>
                                  <FzCard.Body>
                                    <FzCol span={12}>
                                      <FzField
                                        name="code"
                                        dataType={"string"}
                                        label={"Digite o código recebido"}
                                        inputType="text"
                                        fzStyle="small"
                                        onChange={(e) => this.handleCodeChange(e)}
                                        placeHolder="Código"
                                        invalid={this.state.userCode.length > 0 && this.state.userCode.length !== 5}
                                        maxSize={5}
                                        value={this.state.userCode}
                                      />
                                    </FzCol>
                                    <FzCol span={12}>
                                      <div style={{ margin: "0 auto", display: "flex", gap: "5px" }}>
                                        <Button variant="primary" size="sm" style={{ display: "block" }} disabled={!this.isEmailValid() || this.state.userCode === "" || this.state.userKey === undefined} onClick={() => this.loginWithCode()}>Logar no sistema</Button>
                                        <Button
                                          id="btn-code"
                                          variant={this.state.codeRequested ? "success" : "info"}
                                          size="sm"
                                          disabled={!(this.isEmailValid() && this.state.userEmail.length > 5)}
                                          style={{ display: "block" }}
                                          onClick={() => this.requestNewCode()}>Solicitar código</Button>
                                      </div>
                                    </FzCol>
                                  </FzCard.Body>
                                </FzCard>
                              </Accordion.Collapse>
                            </Accordion>
                          </FzCol>
                        </FzRow>
                        <FzRow>
                          <FzCol span={12}>
                            <Accordion>
                              <div className={"fz-radio-container"} onClick={this.requestNewCode}>
                                <Accordion.Toggle
                                  as={Form.Check}
                                  type="switch"
                                  id={'other_login_password'}
                                  eventKey="other_login_password" />
                                <span>
                                  <label htmlFor={`auth-types-code`}>Login com senha</label>
                                </span>
                              </div>
                              <Accordion.Collapse eventKey="other_login_password">
                                <FzCard>
                                  <FzCard.Body>
                                    <FzCol span={12}>
                                      <FzField
                                        name={"password"}
                                        dataType={"string"}
                                        label={"Digite a senha"}
                                        inputProps={{ type: "password" }}
                                        inputType={"text"}
                                        onChange={(e) => this.handlePasswordChange(e)}
                                        placeHolder={"Senha do sistema"}
                                        value={this.state.userPassword}
                                      />
                                      <Button
                                        variant="primary"
                                        size="sm"
                                        style={{ display: "block" }}
                                        title="Toda a comunicação com a FinanZero é feita de forma segura com uso de conexções HTTPS"
                                        disabled=
                                        {
                                          !this.isEmailValid() ||
                                          this.state.userPassword === undefined
                                        }
                                        onClick={
                                          () => this.loginWithPassword()
                                        }>
                                        Logar no sistema
                                      </Button>
                                    </FzCol>
                                  </FzCard.Body>
                                </FzCard>
                              </Accordion.Collapse>
                            </Accordion>
                          </FzCol>
                        </FzRow>
                      </FzCard.Body>
                    </FzCard>
                  </Accordion.Collapse>
                </Accordion>
              </div>
            </FzCol>
            <FzCol span={2}></FzCol>
          </FzRow>
        </FzCard.Body>
      </FzCard>
    );
  }
}

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { history } = this.props;
    sessionStorage.removeItem('fz-ga-name');
    sessionStorage.removeItem('fz-ga-imageUrl');
    sessionStorage.removeItem('fz-ga-email');
    sessionStorage.removeItem('fz-ga-token');
    api.clearAuth();
    const nextURL = getLogoutURL(history.location);
    history.push(nextURL);
  }

  render() {
    return <p>Logged out...</p>;
  }
}

export { Login, Logout };