import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { getAcceptedBy } from "../../../services/api/stats";
import { setTTL } from "../../../business/misc-functions";
import { FzTable } from "../../fz/grid/table";
import { buildPeriodFilters } from '../../../components/table-standard';
import { FzCard } from "../../fz/layout/FzCard";
import { StatsDetailHeader } from '../.././../statistics/stats-components';
import { FzNavSelect } from "../../fz/nav";
import { FzSplitLine } from '../../fz/layout';
import { getParameterValue } from '../../../api/parameters-service';

interface AcceptedByProps {
  ns: any;
  metas: {};
  setBlocking: Function
}

export function AcceptedBy({ ns, setBlocking, metas }: AcceptedByProps) {

  const [requestState, setRequestState] = useState("idle")
  const [urlChecked, setUrlChecked] = useState(false)
  const [filters, setFilters] = useState({ period: "day" })
  const [showDataStudio, setShowDataStudio] = useState(false)
  const [dataStudioValue, setDataStudioValue] = useState("")

  useEffect(() => {
    if (urlChecked) {
      setUrlQueryString()
    }
  }, [filters])

  useEffect(() => {
    getQueryStringFromUrl()
    getParameterValue("datastudio.content.iframe", "acceptedby").then((result) => {
      setDataStudioValue(result.value)
    }).catch((err) => {
      console.warn("Not able to get Productivity Data Studio iframe", err)
    })
  }, [])

  function handleShowDataStudio() {
    setShowDataStudio(!showDataStudio)
  }

  function getFilters() {
    const query = filters.period + qs.stringify(filters, { addQueryPrefix: true })
    return query
  }

  function setUrlQueryString() {
    const query = qs.stringify(filters, { addQueryPrefix: true })
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
    history.pushState({ path: newurl }, '', newurl);
    getStatistics()
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params: any = Object.fromEntries(urlSearchParams.entries());
    if (params.period || params.supplierInternalName) {
      setFilters(params)
    } else {
      setUrlQueryString()
    }
    setUrlChecked(true)
  }

  async function getStatistics() {

    try {
      setRequestState("requesting")
      setBlocking(true);
      const acceptedByResults = await getAcceptedBy(getFilters());
      ns.set("acceptedByResults", acceptedByResults, setTTL(5))
      ns.set("filter_periods", buildPeriodFilters(acceptedByResults.statistics))
      setBlocking(false);
      setRequestState("idle");
    } catch (err) {
      let error: $TsFixMe = err
      setRequestState("idle")
      console.warn("API ERROR", error)
      setBlocking(false)
    }
  }

  let data = ns.get("acceptedByResults", {});

  if (!data && requestState === "requesting") {
    return null
  }

  return (
    <>
      {
        !showDataStudio && dataStudioValue !== "" ? 
          <button className="btn btn-primary mt-1 mb-1" onClick={handleShowDataStudio}>
            Data Studio View
          </button> 
          : 
          <button className="btn btn-primary mt-1 mb-1" onClick={handleShowDataStudio}>
            COF View
          </button>
      }
      {
        showDataStudio && dataStudioValue !== "" ? 
          <div dangerouslySetInnerHTML={{ __html: dataStudioValue }} />
      :
        <FzCard>
          <FzCard.Header>
            <FzCard.Title>
              <FzSplitLine>
                <FzSplitLine.Left></FzSplitLine.Left>
                <FzSplitLine.Right>
                  <FzNavSelect
                    value={filters.period}
                    options={[
                      { "code": "day", "description": "Daily" },
                      { "code": "week", "description": "Weekly" },
                      { "code": "month", "description": "Monthly" },
                    ]}
                    onChange={(value) => {
                      setFilters({ ...filters, period: value })
                    }}
                  />
                </FzSplitLine.Right>
              </FzSplitLine>
              <div className="mt-3">
                <StatsDetailHeader
                  title={'Accepted'}
                  periods={undefined}
                  currPeriod={undefined}
                  onChange={undefined}
                  stats={data}
                />
              </div>
            </FzCard.Title>
          </FzCard.Header>
          <FzCard.Body>
            <FzTable
              exportDownload
              ns={ns}
              context={"qualified"}
              data={data.statistics}
              metas={metas}
              visibleColumns={["key", "period", "acceptedBy", "product", "quantity", "averageApplied", "averageAccepted"]}
              clipboard
            />
          </FzCard.Body>
        </FzCard>
      }
    </>
  );
}