import api, { misApi } from './client-api';

const FormalizationStateDescriptions = {
  1: "Não iniciado",
  2: "Parcial",
  4: "Enviado para análise",
  6: "Parcialmente recusado",
  8: "Aguardando assinatura",
  16: "Finalizado (assinado)",
  32: "Pago",
  0x8000: "Cancelado"
}

export const getFormalization = (applicationId, bidId) => {
  return api.Get("/applications/" + applicationId + "/bids/" + bidId + "/formalization")
}

export const getFormalizationsFromApplication = (appId) => {
  return api.Get('/applications/' + appId + '/formalizations');
}

export const formalizationState = (state) => {
  return FormalizationStateDescriptions[state] || "n/a"
}

export const getFormalizationDocumentUrl = (applicationId, bidId, documentType, fmt) => {
  let path = "applications/" + applicationId + "/bids/" + bidId + "/formalization/documents/" + documentType + "?fmt=" + fmt + "&token=" + api.getAuth()
  return api.baseUrl + path;
}

export const getDocumentUrl = (documentId, fmt) => {
  let path = `documents/${documentId}?fmt=${fmt}&token=${api.getAuth()}`;
  return api.baseUrl + path;
}

export const setFormalizationStateSent = (appId, bidId) => {
  var serviceUrl = '/applications/' + appId + '/bids/' + bidId + '/formalization/statesent';
  var data = {};
  return api.Post(serviceUrl, data);
}

export const getFomalizationDashboard = () => {
  let serviceURL = '/formalizations/dashboard ';
  return misApi.Get(serviceURL);
}

export const getAnalyticsUnicoReports = (from, to) => {
  return []
}

export const getSummariesUnicoReport = (period = 'week') => {
  return misApi.Get(`/validators/unico/${period}`)
}
