import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { ManagerScreening as ManagerScreeningContainer } from '../../../Containers/Screening/ManagerScreening'
import { 
  claimNextScreening,
  getScreeningByPersonalNumberOrApplicationid
} from '../../../services/api/screening'

const visibleColumns = [
  "supplier",
  "bid.approvedAmount",
  "bid.monthlyInstallment",
  "bid.approvedPeriodMonths",
  "bid.interestRate",
  "created",
  "screeningId",
]

const ManagerScreening = ({
  ns
}) => {
  const history = useHistory()
  const [showTost, setShowTost] = useState(false)
  const [toastType, setToastType] = useState('warning')
  const [toastMessage, setToastMessage] = useState('')
  const [screenings, setScreenings] = useState({
    fullname: '',
    bids: []
  })
  const [formData, SetFormData]: $TsFixMe = useState({
    applicationId: null,
    personalNumber: null
  })

  const handleSearchScreenings = async () => {
    try {
      const { applicationId, personalNumber } = formData
      let query = applicationId && { applicationId }
      if (!applicationId && personalNumber) {
        query = { personalNumber }
      }
      
      if (query) {
        const res = await getScreeningByPersonalNumberOrApplicationid(query)
        setScreenings(res)
        if(res?.bids && res?.bids?.length < 1) {
          setToastMessage('Nenhuma proposta encontrada.')
          setToastType('danger')
          setShowTost(true)
        }
      }
    } catch (error) {
      let err: $TsFixMe = error
      console.error(err)
      if(err.response.status === 500){
        setToastMessage('O Application ID informado é inválido.')
        setToastType('danger')
        setShowTost(true)
      }
    }
  }

  const handleChangeValue = (value, name) => {
    const newData = (
      name === 'applicationId' 
        ? { [name]: value, personalNumber: null }
        : { [name]: value, applicationId: null }
    )
    SetFormData(newData)
  }

  const handleGoToDetail = async screeningId => {
    try {
      const { data, status } = await claimNextScreening(screeningId)
      ns.setShared("SCREENING.screening_response")
      if (status !== 204) {
        history.push("/screening/initial-analysis")
      } else {
        setToastMessage('Hm, parece que não temos novos clientes na fila. Pausa para um café :D')
        setToastType('warning')
        setShowTost(true)
      }
    } catch (error) {
      let err: $TsFixMe = error
      console.error(err)
    }

  }

  const handleTostClose = () => setShowTost(false)

  return (
    <ManagerScreeningContainer 
      ns={ns}
      visibleColumns={visibleColumns}
      data={screenings}
      formData={formData}
      handleSearchScreenings={handleSearchScreenings}
      handleChangeValue={handleChangeValue}
      handleGoToDetail={handleGoToDetail}
      handleTostClose={handleTostClose}
      showTost={showTost}
      toastType={toastType}
      toastMessage={toastMessage}
    />
  )
}

export { ManagerScreening }
