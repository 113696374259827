import React, { useEffect, useState } from 'react';
import api from '../../../api/client-api';
import { get } from 'lodash';
import { FormattedDate, FormattedTime } from 'react-intl';
import { FzButton, FzField } from '../../../ui/fz/form';
import { FzBadge, FzCard, FzSplitLine } from '../../../ui/fz/layout';
import { FzAccordion } from '../../../ui/fz/layout/FzAccordion';
import { setCustomerDataToNs } from '../../../biz/user';
import { finalizeTicketAttendance, getApplicationTickets} from '../../../services/api/hermes';
import { getContactsForApplicationId } from '../../../api/crm';
import { FzToast } from '../../../ui/fz/form/notification/toast';
import { FzIcons } from '../../../ui/fz/form/icon';
import { addComment } from '../../../api/applications';

type TreatmentOptions = {
  code: string;
  description: string;
  alternateDescription?: string;
  products?: string;
  filter?: string;
};

type OptionsSelect = {
  code: string;
  description: string;
};

type Tickets = {
  _id: string;
  cadencia: number;
  created: Date;
  validUntil: Date;
  readyTime: Date;
  postponedReason?: string;
  queueId: string;
  queueName: string;
  applicationId: string;
  bidId: string;
  supplierInternalName: string;
  branch: string;
  event: string;
  outcome?: string;
  updated: Date;
  updatedBy?: string;
  locked: boolean;
  lockedBy?: string;
  closedReason?: string;
  closedDate?: Date;
  closedBy?: string;
  closedByUserName?: string;
  contacts?: Array<string>;
};

const hermesProspectEvents = [
  "/application/validate",
  "/application/complete"
];

const toastDefaultConfig = {
  fzStyle: 'danger',
  headerMessageTost: 'Error',
  bodyMessageTost: 'Erro interno, tente novamente!',
  show: false,
  delay: 5000,
  autoHide: false,
  close: () => null,
};

const toastMessages = {
  getTreatments: {
    error: {
      fzStyle: 'danger',
      headerMessageTost: 'Erro: buscar os tratamentos',
      bodyMessageTost: 'Não conseguimos traduzir as opções de atendimento',
    },
  },
  finishAttendance: {
    error: {
      fzStyle: 'danger',
      headerMessageTost: 'Erro ao finalizar o atendimento',
      bodyMessageTost: 'Ocorreu algum erro ao finalizar o atendimento',
    },
    success: {
      fzStyle: 'success',
      headerMessageTost: 'Hermes',
      bodyMessageTost: 'Atendimento realizado com sucesso.',
    },
  },
  getContactsForApplicationId: {
    error: {
      fzStyle: 'danger',
      headerMessageTost: 'Erro ao localizar os comentários',
      bodyMessageTost:
        'Não conseguimos identificar o histórico de comentários dessa proposta',
    },
  },
  getTickets: {
    error: {
      fzStyle: 'danger',
      headerMessageTost: 'Erro ao localizar os tickets',
      bodyMessageTost:
        'Não conseguimos identificar o histórico de tickets dessa proposta',
    },
  },
};

const CommentsQueueHermes = (app, tickets, contacts, supplier) => {
  const [stateIcon, setStateIcon] = useState<Array<any>>([true]);
  let actualComents: Array<any> = [];
  let comments: Array<any> = [];
  let comment = {};

  function setOpen(index: number) {
    stateIcon[index] = stateIcon[index] ? false : true;
    setStateIcon([...stateIcon]);
  }
  tickets &&
    tickets.map((ticket) => {
      if (supplier === ticket.supplierInternalName && ticket.contacts && ticket.outcome != 'not-initialized' && ticket.outcome != 'in-analysis') {
        comment = {
          id: ticket._id,
          supplier: getSupplierName(ticket.supplierInternalName, app, ticket),
          created: ticket.closedDate,
          queue: ticket.queueName,
          userName: ticket.closedByUserName,
          comment: (ticket.contacts[0] && ticket.contacts[0].comment) ? ticket.contacts[0].comment : 'Sem comentário'
        };
        let contact =
          contacts &&
          contacts.find((contact) => contact.id === ticket.contacts[0]);
        if (contact) {
          comment = {
            ...comment,
            createdComment: contact.created,
            userName: contact.userName,
            comment: contact.comments,
          };
        }
        comments.push(comment);
      }
    });

  comments = comments && comments.sort(compareDates);
  comments &&
    comments.map((comment, i) => {
      if (comment) {
        return actualComents.push(
          <div
            style={{
              background: '#F2F2F2',
              border: '1px solid #DFDFDF',
              borderRadius: '0.25rem',
              padding: '0.5rem',
              marginBottom: '0.5rem',
            }}
          >
            <FzAccordion defaultActiveKey={comments[0].id}>
              <FzAccordion.Toggle FzElementType="h9" eventKey={comment.id}>
                <div
                  style={{
                    width: 'auto',
                    marginBottom: '0.5rem',
                    padding: '0.5rem',
                  }}
                  onClick={() => setOpen(i)}
                >
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      Ticket {comment.id} -{' '}
                      <FormattedDate value={comment.created} /> às{' '}
                      <FormattedTime value={comment.created} />
                    </div>
                    {stateIcon[i] ? (
                      <FzIcons icon={'vector-open'} />
                    ) : (
                      <FzIcons icon={'vector-closed'} />
                    )}
                  </div>
                  <div> Parceiro: {comment.supplier} </div>
                  <div>Fila: {comment.queue}</div>
                </div>
              </FzAccordion.Toggle>

              <FzAccordion.Collapse eventKey={comment.id} className="">
                {comment.comment && <div
                  style={{
                    padding: '0.75rem',
                    background: '#FFFFFF',
                    border: '1px solid #DFDFDF',
                    borderRadius: '0.25rem',
                  }}
                >
                  <FzField
                    key={i}
                    readOnly
                    name={'comment' + i}
                    dataType="string"
                    inputType="textarea"
                    value={comment.comment}
                    label={
                      <FzSplitLine>
                        <FzSplitLine.Left>
                          {comment.userName} em{' '}
                          <FormattedDate value={comment.created} /> às{' '}
                          <FormattedTime value={comment.created} />
                        </FzSplitLine.Left>
                      </FzSplitLine>
                    }
                    data={app}
                  />
                </div>}
              </FzAccordion.Collapse>
            </FzAccordion>
          </div>
        );
      }
    });

  return actualComents.length > 0 ? actualComents : null;
};

const ExistingCommentsHermes = ({ app, tickets, contacts, supplier }) => {
  const ticketsHermesParse = Array.isArray(tickets) && tickets.map((ticket) => {
    if(ticket && ticket.contacts && ticket.contacts.length > 0) {
      return ticket
    }
    return ({
      ...ticket, 
      contacts: ticket && ticket.contacts ? ticket.contacts.map(({ contactId }) => contactId ) : []
    })
  })
  const commentsHermes =
    ticketsHermesParse &&
    app &&
    CommentsQueueHermes(app, ticketsHermesParse, contacts, supplier);
  return (
    <>
      <FzCard fzStyle="discrete" nested>
        <FzCard.Heading fzStyle="">
          <FzCard.Title>Histórico de Tickets</FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>{commentsHermes}</FzCard.Body>
      </FzCard>
    </>
  );
};

const AttendanceOutcomeHermes = (props) => {
  const [toast, setToast] = useState(toastDefaultConfig);
  const [exitAttendance, setExitAttendance] = useState(undefined);
  const [comment, setComment] = useState('');
  const [notifyEmail, setNotifyEmail] = useState('');
  const [notifyEmailOther, setNotifyEmailOther] = useState('');
  const [notifySMS, setNotifySMS] = useState('');
  const [notifyWhats, setNotifyWhats] = useState('');
  const [notifyPhone, setNotifyPhone] = useState('');
  const [notifyChat, setNotifyChat] = useState('');
  
  const [isLoading, setIsLoading] = useState(false);
  const [treatmentOptions, setTreatmentOptions] = useState<Array<TreatmentOptions>>([]);
  const basePath = props.basePath;
  const notificationModes = [
    { code: 'sms', description: 'Por SMS' },
    { code: 'email', description: 'Por Email' },
  ];
  const furtherNotificationModes = [
    {
      code: 'whats',
      description: 'Whatsapp',
    },
    {
      code: 'phone',
      description: 'Por Telefone',
    },
    {
      code: 'chat',
      description: 'Via Chat',
    },
    {
      code: 'email',
      description: 'Via e-mail',
    },
  ];

  function onTextChange(newText) {
    setComment(newText);
  }

  function onNotifyChange(newList) {
    setNotifySMS(newList.indexOf('sms') >= 0 ? ' * Notificado por SMS.' : '');
    setNotifyEmail(
      newList.indexOf('email') >= 0 ? ' * Notificado por E-mail.' : ''
    );
  }

  function onNotifyFurtherChange(newList) {
    setNotifyWhats(
      newList.indexOf('whats') >= 0 ? ' * Notificado por Whatsapp.' : ''
    );
    setNotifyPhone(
      newList.indexOf('phone') >= 0 ? ' * Notificado por Telefone.' : ''
    );
    setNotifyChat(
      newList.indexOf('chat') >= 0 ? ' * Notificado via Chat.' : ''
    );
    setNotifyEmailOther(
      newList.indexOf('email') >= 0 ? ' * Notificado via e-mail.' : ''
    );
  }

  function onExitAttendance(newValue) {
    setExitAttendance(newValue);
  }

  function getNotificationList() {
    let notify: Array<string> = [];
    if (notifyEmail) {
      notify.push('email');
    }
    if (notifySMS) {
      notify.push('sms');
    }
    return notify;
  }

  function getFurtherNotificationList() {
    let notify: Array<string> = [];
    if (notifyWhats) {
      notify.push('whats');
    }
    if (notifyPhone) {
      notify.push('phone');
    }
    if (notifyChat) {
      notify.push('chat');
    }
    if (notifyEmailOther) {
      notify.push('email');
    }
    return notify;
  }

  useEffect(() => {
    async function setTreatments() {
      await getTreatments();
    }

    setTreatments();
  }, []);

  async function getTreatments() {
    try {
      const treatments: Array<TreatmentOptions> =
        await api.domains.getDomainValues('attendance-treatments');
      setTreatmentOptions(treatments);

      if (props.readOnly) {
        console.warn(
          'AttendanceOutcome does not work as a read-only component'
        );
      }
    } catch (err) {
      handleToast('getTreatments.error');
      console.error(err);
      return null;
    }
  }

  function finishAttendanceOK() {
    finishThisAttendance(true);
  }

  function finishAttendanceNoContact() {
    finishThisAttendance(false);
  }

  async function finishThisAttendance(success_talk) {
    setIsLoading(true);
    let cd = props.ns.get('customer');
    if (!cd) {
      return;
    }

    let outcome;
    let closedReason = success_talk ? 'finished' : 'no-contact';
    let app = props.app;
    let treatments = props.ns.get('crm.treatments.' + app.id) || '';
    let treatmentsArray = props.ns.get('crm.treatmentsArray.' + app.id) || [];
    let comments = comment ? comment : '';
    let treatmentsComment = 'Tratamento não selecionado!';

    if (treatmentOptions && treatmentOptions.length > 0 && exitAttendance) {
      let treatment = treatmentOptions.find(
        (item) => item.code === exitAttendance
      );
      treatmentsComment = treatment
        ? treatment.description
        : 'Tratamento não selecionado!';
    }

    if (!success_talk) {
      treatmentsComment += ' * Não Consegui Falar';
    }

    treatmentsComment +=
      notifyEmail +
      notifySMS +
      notifyWhats +
      notifyChat +
      notifyEmailOther +
      notifyPhone +
      (treatments ? ' * ' + treatments : '');

    if (notifyEmailOther) {
      treatments += ' * Notificado por E-mail';
      treatmentsArray.push('notified-email');
    }
    if (notifySMS) {
      treatments += ' * Notificado por SMS';
      treatmentsArray.push('notified-sms');
    }
    if (notifyWhats) {
      treatments += ' * Notificado por Whatsapp';
      treatmentsArray.push('notified-whatsapp');
    }
    if (notifyPhone) {
      treatments += ' * Notificado por Telefone';
      treatmentsArray.push('notified-phone');
    }
    if (notifyChat) {
      treatments += ' * Notificado via Chat';
      treatmentsArray.push('notified-chat');
    }

    if (notifyEmail) {
      treatments += ' * Notificado via e-mail';
      treatmentsArray.push('notified-email');
    }
    if (exitAttendance && exitAttendance !== 'undefined') {
      outcome = exitAttendance;
      treatmentsArray.push(exitAttendance);
    }

    let contactId = cd.contactid;
    let contacts;
    let appId = app.id;

    if (cd.contacts) {
      contacts = cd.contacts.find(function (x) {
        return x.applicationId === appId;
      });
    }
    let product =
      contacts && contacts?.applicationId === app.id ? contacts?.product : null;
    comments = comments + ' * ' + treatmentsComment;
    const contactActionsTaken = treatmentsArray.map((act) => ({
      applicationid: appId,
      product: product,
      treatment: act,
    }));

    finishTicketCreation(closedReason, outcome, app, comments, contactActionsTaken, treatments)
  }

  function checkExitAttendance() {
    if (!exitAttendance || exitAttendance === 'undefined' || isLoading) {
      return true;
    }
  }

  async function finishTicketCreation(closedReason, outcome, app, comments, contactActionsTaken, treatments){
    const notificationsSent = getNotificationList()
    const moreNotifications = getFurtherNotificationList()

    try{
      const payload = {
        closedReason,
        outcome,
        applicationId: app.id,
        applicationState: app.applicationState,
        contacts: [
            {
            contactId: app.contactId,
            comment: comments,
            notificationsSent,
            moreNotifications,
            treatments: contactActionsTaken,
            contactTarget: app.telephoneNumber,
            personalNumber: app.personalNumber,
            product: app.product,
            customerId: app.customerId,
            queue: "hermes",
            treatmentsSummary: treatments
          }
        ]
      }
    
      const ticketResult = await finalizeTicketAttendance(props.ticket._id, payload)
      if(ticketResult){
        if(notificationsSent.length > 0){
          await addComment(app.id, comment, notificationsSent.includes('email'), notificationsSent.includes('sms'))
        }
        props.ns.unset('ticket')
        setIsLoading(false)
        handleToast('finishAttendance.success')
        setTimeout(() => {
          props.history.push(basePath)
        }, 3000);
      }
    }catch(err){
      setIsLoading(false);
      handleToast('finishAttendance.error')
      console.warn('FinishAttendance ERROR:', err)
    }
  }

  function exitTreatmentOptions() {
    let options: Array<OptionsSelect> = [];
    if (treatmentOptions) {
      const treatments = treatmentOptions;
      treatments.map((treatment) => {
        return options.push({
          code: treatment?.code,
          description: treatment?.description,
        });
      });
    }

    return options;
  }

  function handleToast(type) {
    setToast({
      ...get(toastMessages, type, {}),
      show: true,
      delay: 5000,
      autoHide: true,
      close: () => setToast(toastDefaultConfig),
    });
  }

  return (
    <>
      <FzCard fzStyle="discrete" nested>
        <FzCard.Body>
          <FzField
            inputType="textarea"
            dataType="string"
            label="Comentário do atendimento do Ticket"
            value={comment}
            name="comment"
            inputProps={{
              showLength: true,
              maxSize: notifyEmail ? 4000 : 140,
            }}
            validationMessage="Texto é muito grande para enviar por SMS"
            onChange={onTextChange}
          />
          <FzField
            inputType="multiselect"
            dataType="array"
            label="Enviar notificações para o cliente"
            value={getNotificationList()}
            name="notify"
            inputProps={{ options: notificationModes }}
            validationMessage="Preenchimento obrigatório"
            onChange={onNotifyChange}
          />
          <FzField
            inputType="multiselect"
            dataType="array"
            label="Demais notificações utilizadas"
            value={getFurtherNotificationList()}
            name="furtherNotify"
            inputProps={{ options: furtherNotificationModes }}
            validationMessage="Preenchimento obrigatório"
            onChange={onNotifyFurtherChange}
          />
          <FzField
            inputType="select"
            dataType="string"
            label="Informe o tratamento efetuado"
            value={exitAttendance}
            name="exitAttendance"
            inputProps={{ options: exitTreatmentOptions() }}
            validationMessage="Preenchimento obrigatório"
            onChange={onExitAttendance}
          />

          <div style={{ display: 'flex', gap: '1rem', alignItems: 'end' }}>
            <FzButton
              fzStyle="regular"
              disabled={checkExitAttendance()}
              onClick={finishAttendanceOK}
            >
              Atendimento com Sucesso
            </FzButton>
            <FzButton
              fzStyle="alert"
              disabled={checkExitAttendance()}
              onClick={finishAttendanceNoContact}
            >
              Não Consegui Falar
            </FzButton>
          </div>
        </FzCard.Body>
      </FzCard>
      <FzToast {...toast} />
    </>
  );
};

function getSupplierName(supplierInternalName: string, application, ticket): string {
  if(supplierInternalName){
    const bid = application.auction.bids.find(
      (bid) => bid.supplier.internalName === supplierInternalName
    );
    return bid.supplier.name;
  }

  if (hermesProspectEvents.indexOf(ticket.event) > -1) {
    return 'Prospect'
  } else {
    return 'Inbound'
  }
}

function compareDates(a, b) {
  const preference1 = a.locked;
  const preference2 = b.locked;
  const d1 = a.created;
  const d2 = b.created;

  let comparison = 0;
  if (preference1) {
    comparison = -1;
  } else if (preference2) {
    comparison = -1;
  } else if (d1 > d2) {
    comparison = -1;
  } else if (d1 < d2) {
    comparison = 1;
  }
  return comparison;
}

export const CustomerAttendanceHermes = ({ ns, app, history, onNeedUpdate, basePath, category, ticketProp }) => {
  const [toast, setToast] = useState(toastDefaultConfig);
  const [ticketSelected, setTicketSelected] = useState<Tickets>();
  const [ticket, setTicket] = useState<Tickets>(ticketProp)
  const [ticketsHeader, setTicketsHeader] = useState<Array<Tickets>>([]);
  const [ticketsApp, setTicketsApp] = useState<Array<Tickets>>([]);
  const [contacts, setContacts] = useState<Array<any>>();

  useEffect(() => {
    setCustomerDataToNs(ns.get('application.customerId'), ns);
    getContactsForApplicationId(app.id)
    .then((data) => {
      setContacts(data);
    })
    .catch((err) => {
      console.error(err);
    });
    
    getApplicationTickets(app.id)
    .then((data) => {
        let currentTicket: any = ticket
        if(!currentTicket && data.length > 0){
          for(let i = 0; i < data.length; i++){
            if(data[i].locked){
              currentTicket = data[i]
              setTicket(data[i])
            }
          }
          currentTicket ? currentTicket : currentTicket = data[0]
        }
        if(currentTicket && currentTicket._id){
          setTicketsApp(data);
          setTicket(currentTicket)
          const ticketsForSuppliers = suppliersContainsTickets(data);
          ticketsForSuppliers.map(
            (t) => currentTicket._id === t._id && setTicketSelected(t)
          );
          setTicketsHeader(ticketsForSuppliers);
        }
      })
      .catch((err) => {
        handleToast('getTickets.error');
        console.error(err);
      });
  }, []);

  function newComment() {
    if (api.isUserSales() && !ns.isEditing()) {
      return (
        <AttendanceOutcomeHermes
          ns={ns}
          app={app}
          history={history}
          onApplicationUpdated={onNeedUpdate}
          basePath={basePath}
          ticket={ticketSelected}
        />
      );
    }

    return null;
  }

  function suppliersContainsTickets(ticketsAppId): Array<any> {
    const ticketsForSuppliers: any = [];
    const tickets = ticketsAppId || []
    tickets.forEach((ticket) => {
      if (ticket && ticket.locked) {
        ticketsForSuppliers.push(ticket);
      } else {
        let suppliersRepeat = ticketsForSuppliers.find(
          (t) => t.supplierInternalName === ticket.supplierInternalName
        );
        if (!suppliersRepeat) {
          ticketsForSuppliers.push(ticket);
        }
      }
    });
    return ticketsForSuppliers.sort(compareDates);
  }

  function buttonTickets() {
    const buttons = ticketsHeader.map((t, index) => {
      return (
        <FzButton
          key={index}
          fzStyle="outline-primary"
          active={ticketSelected ? ticketSelected._id === t._id : ticket?._id === t._id}
          onClick={() => setTicketSelected(t)}
        >
          {getSupplierName(t.supplierInternalName, app, t)}
        </FzButton>
      );
    });

    return buttons;
  }

  function mainTickets() {
    return (
      <>
        {ticketSelected && (
          <div
            style={{
              background: '#F2F2F2',
              padding: '1rem',
              border: '1px solid #DFDFDF',
              borderRadius: '0px 0px 3px 3px',
            }}
          >
            <div>
              Ticket {ticketSelected._id} -{' '}
              <FormattedDate value={ticketSelected.created} />
            </div>
            {ticketSelected.locked ? (
              <FzBadge fzStyle="attention"> Em atendimento </FzBadge>
            ) : ticketSelected.outcome === 'not-initialized' ? (
              <FzBadge fzStyle="regular"> Não iniciado </FzBadge>
            ) : (
              <>
                <FzBadge fzStyle="success"> Finalizado </FzBadge>
                {
                  ticketSelected.closedReason && (
                  <div>Motivo do fechamento: {ticketSelected.closedReason} </div>
                  )
                }
              </>
            )}
            <div>
              Parceiro:{' '}
              {getSupplierName(ticketSelected.supplierInternalName, app, ticketSelected)}
            </div>
            <div>Fila: {ticketSelected.queueName}</div>
          </div>
        )}
      </>
    );
  }

  function handleToast(type) {
    setToast({
      ...get(toastMessages, type, {}),
      show: true,
      delay: 5000,
      autoHide: true,
      close: () => setToast(toastDefaultConfig),
    });
  }

  if(ticket && ticket._id) {
    return (
      <>
        <FzCard>
          <FzCard.Heading fzStyle="">
            <FzCard.Title>Filtrar tickets por parceiro</FzCard.Title>
          </FzCard.Heading>
          <div style={{ display: 'flex', margin: '1rem', gap: '1rem' }}>
            {buttonTickets()}
          </div>
  
          <FzCard.Body>
            {(ticketSelected?.outcome == 'not-initialized' || ticketSelected?.outcome == 'in-analysis') && mainTickets()}
            <React.Fragment>
              { 
                basePath === '/hermes/tickets' &&
                ticket._id === ticketSelected?._id &&
                ticketSelected?.locked &&
                <div>{newComment()}</div>
              }
              <ExistingCommentsHermes
                app={app}
                tickets={ticketsApp}
                contacts={contacts}
                supplier={ticketSelected?.supplierInternalName}
              />
            </React.Fragment>
          </FzCard.Body>
        </FzCard>
        <FzToast {...toast} />
      </>
    );
  }
  return null
};
