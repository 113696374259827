import React, { Component } from 'react';
import { Jumbotron, Button } from 'react-bootstrap';
import { FzCard, FzRow, FzCol, FzCardGroup } from './ui/fz/layout/index'
import { FzForm } from './ui/fz/layout/index'
import packageJson from '../package.json';
import { getTemplates } from './services/api/admin';
import { FzTable } from './ui/fz/grid/table';
import { FzIcons } from './ui/fz/form/icon'
import { FzButton } from './ui/fz/form'
import isEmpty from 'lodash/isEmpty';
import FieldConnected from './ui/components/form/field-connected'
import { addressByZipCode, cityByName, cityByIBGE } from './api/zipcode';
import { FzAlert } from './ui/fz/form/alert';
import { getCookie, setCookie } from './api/helpers';

class About extends Component {
  constructor(props) {
    super(props);
    this.api = props.fzApi;
    this.state = { emailTemplates: null, open: true, };
    this.doFindAddress = this.doFindAddress.bind(this);
    this.logout = this.logout.bind(this)
    this.toggleHomol = this.toggleHomol.bind(this)

    this.isAdmin = this.api.isUserInRole("admin")
    if (this.isAdmin) {
      getTemplates()
        .then((data) => {
          let et = []
          let emailTemplates = (data.emailTemplates || {})
          for (var t in emailTemplates) {
            if (emailTemplates.hasOwnProperty(t)) {
              et.push({ template: t, emailId: emailTemplates[t] })
            }
          }
          this.setState({ emailTemplates: et, emailDelay: data.postponeSeconds })
        })
    }
  }

  componentDidMount() {
    const { ns } = this.props;
    ns.startEditing()
  }

  execRequests(_filters) {

    if (_filters.zipCode) {
      addressByZipCode(_filters)
        .then(addr => {
          this.setState({ errorMessage: null });
          this.props.ns.set('addr', addr);
        })
        .catch(() => {
          this.props.ns.set('addr', null);
          this.setState({ errorMessage: 'CEP não encontrado.' })
        })
    }

    if (_filters.state && _filters.city) {
      cityByName(_filters)
        .then(addr => {
          this.setState({ errorMessage: null });
          this.props.ns.set('city', addr);
        })
        .catch(
          this.setState({ errorMessage: 'Cidade não encontrado.' }))
    }

    if (_filters.ibge) {
      cityByIBGE(_filters)
        .then(addr => {
          this.setState({ errorMessage: null });
          this.props.ns.set('city', addr);
        })
        .catch(
          this.setState({ errorMessage: 'Cidade não encontrado.' }))
    }
  }

  doFindAddress = () => {
    const { ns } = this.props;
    const _filter = ns.getChanged();
    if (isEmpty(_filter)) {
      this.setState({
        errorMessage: 'Preencha um ou mais valores do filtro.',
      });
    } else {
      this.execRequests(_filter);
    }
  }

  getZipCodeMetas(field) {
    const metas = [
      {
        "zipCode": {
          "path": "zipCode",
          "label": "CEP",
          "section": "residence-data",
          "dataType": "text",
          "inputType": "masked",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": "postal-code",
            "mask": "#####-###",
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": ["zipCode", "neighbourhood", "city", "address1", "state"],
          "validationMessage": "Informe o CEP",
          "pattern": /^\d{5}-?\d{3}$/,
          "placeholder": undefined,
          "tip": undefined,
          "product": ["CP", "HE", "Refin"],
          "isMandatory": () => { return false },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": (value) => value.replace("-", ""),
          "extraValidation": undefined,
          "hookOnSave": undefined
        }
      },
      {
        "city": {
          "path": "city",
          "label": "Cidade",
          "section": "residence-data",
          "dataType": "string",
          "inputType": "text",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": "address-level2",
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": "application",
            "hidden": false,
            "shortLabel": undefined,
            "width": "25ch",
            "filter": { type: 'TextFilter', delay: 500 },
            "dataSort": true,
            "dataAlign": undefined,
            "columnFormat": "cityColumn",
          },
          "relatedFields": undefined,
          "validationMessage": "Informe a cidade de residência",
          "pattern": /^.{3,60}$/,
          "placeholder": "Cidade",
          "tip": undefined,
          "product": ["CP", "HE", "Refin"],
          "isMandatory": () => { return false },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        }
      },
      {
        "state": {
          "path": "state",
          "label": "Estado",
          "section": "residence-data",
          "dataType": "string",
          "inputType": "select",
          "inputProps": {
            "domainName": "states",
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": "address-level1",
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe o estado de residência",
          "pattern": undefined,
          "placeholder": "Estado?",
          "tip": undefined,
          "product": ["CP", "HE", "Refin"],
          "isMandatory": () => { return false },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        }
      },
      {
        "ibge": {
          "path": "ibge",
          "label": "Cod. IBGE",
          "section": "residence-data",
          "dataType": "string",
          "inputType": "text",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": "address-level2",
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": "application",
            "hidden": false,
            "shortLabel": undefined,
            "width": "25ch",
            "filter": { type: 'TextFilter', delay: 500 },
            "dataSort": true,
            "dataAlign": undefined,
            "columnFormat": "cityColumn",
          },
          "relatedFields": undefined,
          "validationMessage": "Informe a cidade de residência",
          "pattern": /^.{3,60}$/,
          "placeholder": "Código IBGE",
          "tip": undefined,
          "product": ["CP", "HE", "Refin"],
          "isMandatory": () => { return false },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        }
      },
      {
        "address1": {
          "path": "address1",
          "label": "Endereço",
          "section": "residence-data",
          "dataType": "string",
          "inputType": "text",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": "address-line1",
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe o endereço",
          "pattern": /^.{5,60}$/,
          "placeholder": "Endereço, ex: Rua da Graça",
          "tip": undefined,
          "product": ["CP", "HE", "Refin"],
          "isMandatory": () => { return false },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        }
      },
      {
        "streetNumber": {
          "path": "streetNumber",
          "label": "Número",
          "section": "residence-data",
          "dataType": "string",
          "inputType": "text",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": "address-line2",
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe o número de residêcia",
          "pattern": /^\d{1,15}$/,
          "placeholder": "Número",
          "tip": undefined,
          "product": ["CP", "HE", "Refin"],
          "isMandatory": () => { return false },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        }
      },
      {
        "address2": {
          "path": "address2",
          "label": "Complemento",
          "section": "residence-data",
          "dataType": "string",
          "inputType": "text",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": "address-line3",
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": undefined,
          "pattern": undefined,
          "placeholder": "Complemento",
          "tip": undefined,
          "product": ["CP", "HE", "Refin"],
          "isMandatory": () => { return false },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        }
      },
      {
        "neighbourhood": {
          "path": "neighbourhood",
          "label": "Bairro",
          "section": "residence-data",
          "dataType": "string",
          "inputType": "text",
          "inputProps": {
            "domainName": undefined,
            "filteredFrom": undefined,
            "options": undefined,
            "maxValue": undefined,
            "minValue": undefined,
            "maxSize": undefined,
            "minSize": undefined,
            "autoComplete": "address-line3",
            "mask": undefined,
            "removeMask": undefined
          },
          "tableProps": {
            "viewsTable": undefined,
            "hidden": false,
            "shortLabel": undefined,
            "width": undefined,
            "filter": undefined,
            "dataSort": undefined,
            "dataAlign": undefined,
            "columnFormat": undefined,
          },
          "relatedFields": undefined,
          "validationMessage": "Informe o bairro de residência",
          "pattern": /^.{5,60}$/,
          "placeholder": "Bairro",
          "tip": undefined,
          "product": ["CP", "HE", "Refin"],
          "isMandatory": () => { return false },
          "isReadOnly": undefined,
          "isVisible": undefined,
          "normalize": undefined,
          "extraValidation": undefined,
          "hookOnSave": undefined
        }
        },
      {
        "name": {
          "label": "Nome da cidade"
        }
      },
      {
        "cityShort": {
          "label": "Cid. encurtado"
        }
      },
      {
        "id": {
          "label": "ID"
        }
      }
    ]

    const meta = metas.find(x => Object.getOwnPropertyNames(x)[0] === field);
    return meta[field];
  }

  getMetas() {
    const metas = {
      "template": {
        "path": "template",
        "dataType": "text",
        "label": "Template",
        "tableProps": {
          "viewsTable": "",
          "hidden": null,
          "shortLabel": null,
          "width": "10ch",
          "filter": { type: 'TextFilter', delay: 1000 },
          "dataSort": true,
          "dataAlign": "center",
          "columnFormat": null,
          "isKey": true,
          "exportFormat": null,
          "rowSpan": null,
          "sortFunc": null
        },
        "tip": null
      },
      "emailId": {
        "path": "emailId",
        "dataType": "text",
        "label": "SendGrid Id",
        "tableProps": {
          "viewsTable": "",
          "hidden": null,
          "shortLabel": null,
          "width": "10ch",
          "filter": { type: 'TextFilter', delay: 1000 },
          "dataSort": true,
          "dataAlign": "center",
          "columnFormat": null,
          "isKey": null,
          "exportFormat": null,
          "rowSpan": null,
          "sortFunc": null
        },
        "tip": null
      },
    }

    return metas
  }

  clearCache = () => {
    this.props.sharedNS.clear()
  }

  logout(e) {
    const { history } = this.props
    history.push("/logout")
  }
  toggleHomol() {
    setCookie("isHomol", getCookie("isHomol") ? (getCookie("isHomol") == "true" ? "false" : "true") : "false")
    console.log(window.finanzero?.isHomol, getCookie("isHomol"))
  }

  render() {
    const { data, ns } = this.props;
    let errorMessage;

    if (this.state.errorMessage) {
      errorMessage = <FzAlert fzStyle="alert">{this.state.errorMessage}</FzAlert>;
    }
    const homolButtonType = (window.finanzero?.isHomol || getCookie("isHomol") === "true") ? "danger" : "default"
    console.log(homolButtonType, window.finanzero?.isHomol, getCookie("isHomol"))
    var user = this.api.getCurrentUser()
    if (user === null) {
      user = {}
    }

    const fieldElms = ['state', 'city', 'zipCode', 'ibge',].map(
      field => {
        return (
          <FieldConnected
            key={field}
            meta={this.getZipCodeMetas(field)}
            data={data}
            ns={ns}
          />
        );
      }
    );

    let cols = [];
    for (let i = 0; i < fieldElms.length; i++) {
      cols.push(<FzCol span={3} key={i}> {fieldElms[i]} </FzCol>);
    }

    let fieldsContent = <FzRow key={0}>{cols}</FzRow>;


    let addr = this.props.ns.get('addr');
    let partialAddress;
    if (addr) {
      let contentLines = [];
      Object.keys(addr).forEach((field) => {
        const meta = this.getZipCodeMetas(field);
        contentLines.push(
          <FzRow key={field}>
            <FzCol span={2}>
              <FzForm.Label>
                <b>{meta.label}: </b>
              </FzForm.Label>
            </FzCol>
            <FzCol>{addr[field]}</FzCol>
          </FzRow>
        );
      });
      partialAddress = <FzCard.Body>{contentLines}</FzCard.Body>;
    }

    let city = this.props.ns.get('city');
    let partialCity;
    if (city) {
      let contentLines = [];
      Object.keys(city).forEach((field) => {
        const meta = this.getZipCodeMetas(field);
        contentLines.push(
          <FzRow key={field}>
            <FzCol span={2}>
              <FzForm.Label>
                <b>{meta.label}: </b>
              </FzForm.Label>
            </FzCol>
            <FzCol>{city[field]}</FzCol>
          </FzRow>
        );
      });
      partialCity = <FzCard.Body>{contentLines}</FzCard.Body>;
    }

    let addressResult;
    addressResult = (
      <>
        {partialAddress}
        {partialCity}
      </>
    )

    let zipcodeArea
    zipcodeArea = (
      <div id="ZipcodeArea" defaultActiveKey={2} accordion>
        <FzCard key={3} expanded={this.state.open} onToggle={() => { }}>
          <FzCard.Heading onClick={() => this.setState({ open: !this.state.open }, this.props.ns.startEditing())}>
            <FzCard.Title toggle componentClass="h3">Busca de Endereços</FzCard.Title>
          </FzCard.Heading>
          <FzCard.Body collapsible>
            <FzCard.Body>
              {fieldsContent}
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FzButton onClick={this.doFindAddress} fzStyle="discrete">
                  Buscar
                </FzButton>
              </div>
            </FzCard.Body>
            {errorMessage}
            {addressResult}
          </FzCard.Body>
        </FzCard>
      </div>
    )

    let adminArea
    if (this.isAdmin && this.state.emailTemplates) {
      adminArea = (
        <FzCardGroup id="AdminArea" defaultActiveKey={0} accordion>
          <FzCard key={1} expanded={this.state.open} onToggle={() => { }}>
            <FzCard.Heading onClick={() => this.setState({ open: !this.state.open })}>
              <FzCard.Title toggle componentClass="h3">Templates de Email</FzCard.Title>
            </FzCard.Heading>
            <FzCard.Body collapsible>
              <FzCard.Body>
                <FzCard>
                  <FzCard.Heading>
                    <FzCard.Title>Segundos de atraso para envio dos email de fechamento: <b>{this.state.emailDelay}</b></FzCard.Title>
                  </FzCard.Heading>
                </FzCard>
                <FzTable
                  data={this.state.emailTemplates}
                  metas={this.getMetas()}
                  ns={this.props.ns}
                />
              </FzCard.Body>
            </FzCard.Body>
          </FzCard>
        </FzCardGroup>
      )
    }
    return (
      <Jumbotron>
        <FzCard>
          <FzCard.Heading>
            <FzCard.Title componentClass="h2">FinanZero COF</FzCard.Title>
          </FzCard.Heading>
          <FzCard.Body>
            <FzRow>
              <FzCol span={6}>
                <p>Versão: <b>{packageJson.version}</b>
                </p>
                <p><b>{user.name}</b> ({user.role})</p>
                <p className={"classForTesteEmail"} >{user.email}</p>
              </FzCol>
              <FzCol span={6}>
                <Button onClick={this.clearCache}> < FzIcons icon={"trash"} />Clear Cache</Button>
                <Button onClick={this.logout}> Logout</Button>
                <Button onClick={this.toggleHomol} variant={homolButtonType}> Homolog</Button>
              </FzCol>
            </FzRow>
          </FzCard.Body>
        </FzCard>
        {adminArea}
        {zipcodeArea}
      </Jumbotron>
    );
  }
}

export default About;