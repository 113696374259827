
import { setBrands, setModelYears } from '../../../biz/metadatas/application';
import { zipCodeAddress } from '../../../api/zipcode'
import { getCarModels, getCarData } from '../../../services/api/bureau/index'



const hookSaveCep = (newValue, oldValue, ns, meta) => {
  if (oldValue !== newValue) {
    return zipCodeAddress(newValue).then(res => {

      const fields = {
        company: {
          "address1": "companyAddress",
          "neighbourhood": "companyNeighbourhood",
          "city": "companyCity",
          "state": "companyState",
        },
        default: {
          "address1": "address1",
          "neighbourhood": "neighbourhood",
          "city": "city",
          "state": "state",
        }
      }

      const group = meta.path.split(".").length > 1 ? meta.path.split(".")[0] + "." : ""
      const type = (/^company.+/i.test(meta.path) ? "company" : "default")

      ns.saveChange(group + fields[type]["address1"], res.address1)
      ns.saveChange(group + fields[type]["neighbourhood"], res.neighbourhood)
      ns.saveChange(group + fields[type]["city"], res.city)
      ns.saveChange(group + fields[type]["state"], res.state)

    }).catch(err => {
      console.err(err)
    })
  }
}

const hookSetModelsOrData = (newValue, oldValue, ns, meta) => {
  return new Promise(async (resolve, reject) => {
    if (oldValue !== newValue && newValue !== "undefined" && newValue.length === 7) {
      const { id } = ns.getChanged();
      try {
        const res = await getCarModels(id, newValue, ns)
        if (res && res.availableModels) {
          const models = res.availableModels.map(i => {
            return { code: i.name, description: i.name, value: i.name }
          })
          ns.setShared("CACHE.domains.models", models)
          const preModel = models.find(m => m.code === ns.getChanged("vehicleSecurity.modelFull"))
          if (!preModel) {
            ns.saveChange("vehicleSecurity.modelFull", "")
          }
          resolve(meta.path)
        } else {
          saveCarDataOnNs(ns, res, newValue)
          resolve(meta.path)
        }
      } catch (err) {
        console.error("Erro ao tentar se comunicar com o bureau. modo offline", err)
        reject(meta.path)
      }
    }
  })
}


const hookGetCarData = async (newValue, oldValue, ns, meta) => {
  if (newValue !== oldValue && newValue !== 'undefined') {
    const { id } = ns.getChanged()
    const { vehicleSecurity } = ns.getChanged()
    try {
      const data = await getCarData(id, vehicleSecurity.registrationNumber, newValue)
      saveCarDataOnNs(ns, data)
      return data
    } catch (e) {
      console.error(e)
      return new Promise((resolve, reject) => {
        reject(meta.path)
      })
    }
  }
}

const saveCarDataOnNs = (ns, data, newCarPlateValue) => {
  const fullYear = new Date().getFullYear()
  const currentObj = JSON.parse(JSON.stringify(ns.getChanged('vehicleSecurity')))
  if (data.modelDetails && (fullYear - data.modelDetails.modelYear) >= 20) {
    return ns.setShared('vehicleSecurityModelfullError', true)
  }

  if (data.modelDetails) {
    for (let item in data.modelDetails) {
      currentObj[item] = data.modelDetails[item]
    }
  }
  if (data.modelDetailsFipe) {
    currentObj.codeFipe = data.modelDetailsFipe.codeFipe
    currentObj.modelFipe = data.modelDetailsFipe.model
    currentObj.valueFipe = data.modelDetailsFipe.value
  }
  if (newCarPlateValue) {
    currentObj.registrationNumber = newCarPlateValue
  }
  ns.saveChange('vehicleSecurity', currentObj)
}

export default { hookSaveCep, hookSetModelsOrData, hookGetCarData }

