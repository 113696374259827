import { BlocklistSearchContainers } from '../../../Containers/Blocklist/BlocklistSearchContainers';
import { MetaBlocklist, IBlockListResponse } from '../../../biz/metadatas/blocklist/blocklist-report';
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { FzCard } from '../../fz/layout/FzCard';
import { FzButton } from '../../fz/form';
import { toastDefaultConfig, blocklistCustomPlaceholder, toastMessages, optionsType } from '../../blocks/blocklist-components/blocklist-custom-fields';
import { getOneBlocklist } from '../../../services/api/bureau';
import { FzAlert } from '../../fz/form/alert';
import FieldConnected from '../../components/form/field-connected';


export function BlocklistSearch({ ns, history }) {
  const [toast, setToast] = useState(toastDefaultConfig);
  const [dataSearchBlockList, setDataSearchBlockList] = useState<IBlockListResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const allowedType = ['email', 'telephone', 'personalnumber'];
  let blocklistData = ns.getChanged() || ns.get('blocklistData') || {};
  let type = ns.getChanged('type');
  let value = ns.getChanged('value');

  useEffect(() => {
    ns.startEditing({
      type: type,
      value: value,
    });
  }, []);

  const handleToast = (type) => {
    setToast({
      ...get(toastMessages, type, {}),
      show: true,
      delay: 5000,
      autoHide: true,
      close: () => setToast(toastDefaultConfig),
    });
  };

  const completeBlocklistData = (data) => {
    data = {
      ...data,
      daysinblocklist: Math.floor(
        (new Date(data.onblocklistuntil).getTime() - new Date().getTime()) /
          (1000 * 3600 * 24)
      ),
      outcount: Math.floor(
        (new Date().getTime() - new Date(data.created).getTime()) /
          (1000 * 3600 * 24)
      ),
    };
    
    data.type =
      data.type === 'docid'
        ? optionsType.find((it) => it.code === data.doctype)?.description
        : optionsType.find((it) => it.code === data.type)?.description;

    setDataSearchBlockList([data]);
  };

  const getSearchBlocklist = async (): Promise<void> => {
    try {
      setLoading(true);
      setDataSearchBlockList([]);
      if (allowedType.indexOf(type) === -1) {
        const data = await getOneBlocklist({ type: 'docid', value: value });
        ns.set('blocklistData', data);
        completeBlocklistData(data);
        setLoading(false);
      } else {
        const data = await getOneBlocklist({ type: type, value: value });
        ns.set('blocklistData', data);
        completeBlocklistData(data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      handleToast('get.error');
      setLoading(false);
    }
  };

  const componentValue = (type: string) => {
    let metaValue = MetaBlocklist.getField('value');
    const placeholdeValue = blocklistCustomPlaceholder(type);
    metaValue.placeholder = placeholdeValue;

    if (value && type !== 'email') {
      let clearValue =
        type === 'telephone' || type === 'personalnumber'
          ? value.replace(/[^0-9]/g, '')
          : value.replace(/[^a-zA-Z0-9]/g, '');
      value = clearValue;
    }

    return <FieldConnected meta={metaValue} data={blocklistData} ns={ns} />;
  };

  return (
    <FzCard.Body fzStyle={{ padding: '1.5rem' }}>
      <FzCard>
        <FzCard.Header>
          <FzCard.Title> Buscar cliente </FzCard.Title>
        </FzCard.Header>

        <div
          className={'fz-panel-border-padding'}
          style={{ display: 'flex', alignItems: 'center', gap: '2rem', border: 'none' }}
        >
          <div>
            <FieldConnected
              meta={MetaBlocklist.type}
              data={blocklistData}
              ns={ns}
            />
          </div>
          <div> {type && componentValue(type)} </div>
          <div>
            { type && value && 
              <FzButton
                onClick={() => {
                  getSearchBlocklist();
                }}
                tip={'Buscar'}
                className={null}
                block={true}
                fzStyle={'regular'}
                disabled={loading}
              >
                Buscar
              </FzButton>
            }
          </div>
        </div>
      </FzCard>

      { loading && (
        <FzAlert fzStyle="attention">
          Carregando os dados do servidor!
        </FzAlert>
      )}

      <BlocklistSearchContainers
        data={dataSearchBlockList}
        ns={ns}
        meta={MetaBlocklist}
        toast={toast}
      />
    </FzCard.Body>
  );
}
