import api from './client-api';

export const getUsersByFilter = (filter) => {
  var serviceUrl = '/auth/all?role=customer';

  serviceUrl += api._buildQueryString(filter);
  return api.Get(serviceUrl)
}

export const exists = (query = "email", value = "") => {
  return api.Get(`/applications/exists?${query}=${value}`)
}

export const requestCode = (email, pn) => {
  return api.Post("/auth/request-code", {
    email: email,
    personalNumber: pn
  })
}

export const requestCodeByType = (type, data) => {
  return api.Post("/auth/request-code", {
    [type]: data[type]
  })
}

export const loginWithCode = (key, code) => {
  return api.Post("/auth/login/code", { Key: key, Code: code })
}

export const loginWithPassword = (email, password) => {
  var json = JSON.stringify({Email: email, Password: password})
  return api.Post("/auth/login/password", json)
  .then(json => {
    setAuth(json.token);
    setCurrentUser(json.user);
  });
}

export const loginWithPreAuth = (key) => {
  return api.Post("/auth/login/preauth", { key })
}

export const loginWithGAuth = (email, gtoken, telephonenumber = "", personalnumber = "") => {
  return api.Post("/auth/login/gauth", { email, gtoken, telephonenumber, personalnumber })
}

export const validateSession = () => {
  return api.Post("/auth/validate", {})
}

export const revokeTokens = () => {
  return api.Delete("/auth/token")
}

export const setAuth = (token, user, gtoken) => {
  return api.setAuth(token, user, gtoken)
}

export const getAuth = (auth2) => {
  return api.getAuth(auth2)
}

export const clearAuth = () => {
  return api.clearAuth()
};

export const getCurrentSupplier = () => {
  return api.getCurrentSupplier()
}

export const getCurrentUser = () => {
  return api.getCurrentUser()
}

export const setCurrentUser = (user) => {
  return api.setCurrentUser(user)
}
export const sendPreAuthLink = (userId, applicationId) => {
  const serviceUrl = `/auth/${userId}/pre-auth-link`;
  return api.Post(serviceUrl, {applicationId})
};
