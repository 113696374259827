import api, { misApi } from "../../../api/client-api";

export const getDeletedReason = (period) => {
  return misApi.Get(`/lending/applications/deletedreason/${period}`);
}

export const getQualifiedLeads = (period) => {
  return misApi.Get("/stats/qualified/" + period);
}

export const getAcceptedBy = (period) => {
  return misApi.Get("/lending/applications/acceptedby/" + period);
}

export const getProductChanges = (period) => {
  return api.Get("/stats/productchange/" + period);
}

export const getFormValidationStats = (period) => {
  return misApi.Get("/stats/formvalidation/" + period);
}

export const getPaidApplications = (period) => {
  return misApi.Get("/lending/applications/paid/" + period);
}

export const getPaidApplicationsDS = () => {
  return api.Get("/parameters/datastudio.content.iframe/paid")
}

export const getRevenueModelOverview = (period) => {
  return misApi.Get("/lending/applications/revenueoverview/" + period);
}

export const getRevenueModelPerPartners = (period) => {
  return api.Get("/stats/revenuepartners/" + period);
}


export const getFormBusinessStats = (period) => {
  return misApi.Get("/lending/funnel/formprogression/" + period);
}

export const getGeneralProgressionDS = () => {
  return api.Get("/parameters/datastudio.content.iframe/general-progression")
}

export const getFormUXStats = (period) => {
  return api.Get("/stats/formvalidation/" + period);
}

export const getFormalizationDocs = (period) => {
  return api.Get("/stats/formalizationdocs/" + period);
}

export const fetchApplicationsStats = (type, period, dateRef) => {
  var formattedDate = dateRef.toISOString().slice(0, 10);
  return api.Get('/statistics/' + type + '/' + period + '?createdAfter=' + formattedDate)
}

export const fetchCreditScoreStats = (dateRef) => {
  var formattedDate = new Date(dateRef).toISOString().slice(0, 10);
  return api.Get('/statistics/creditscore?createdAfter=' + formattedDate)
}

export const getFzScoreStats = async (period) => {
  const data = await misApi.Get('lending/applications/fzscore/' + period)
  const ranges = [...new Set(data.statistics.map(it => it.score))].reduce((acc, item) => ({ ...acc, [item]: 0 }), {});
  return { ...data, statistics: data.statistics.map(it => ({ ...ranges, ...it, [it.score]: it["count-bids"] })) }
}

export const getFinanvcEvents = (period) => {
  return misApi.Get('finanvc/events/' + period)
}

export const getStatisticsFunnelOperationalOverview = (period) => {
  return misApi.Get('lending/funnel/operational/' + period)
}

export const getStatisticsFunnelOperationalOverviewDS = () => {
  return api.Get("/parameters/datastudio.content.iframe/funnel-overview")
}

export const getStatisticsRunRateDS = () => {
  return api.Get("/parameters/datastudio.content.iframe/run-rate")
}


export const getStatisticsFunnelPartnersOverviewDS = () => {
  return api.Get("/parameters/datastudio.content.iframe/funnel-partners")
}


export const getStatisticsFunnelFormalizations = (period) => {
  return misApi.Get('lending/funnel/formalizations/' + period)
}

export const getStatisticsFunnelOpePartners = (period, product) => {
  return misApi.Get("/lending/funnel/partners/" + period + "?product=" + product);
}


export const getStatisticsFunnelOpePartnersTest = (period) => {
  return misApi.Get("/lending/funnel/partners/" + period);
}

export const getFunnelOpePartners = (period, product) => {
  return api.Get("/stats/operationalpartners/" + period + "?product=" + product);
}

export const getCapacityReviewStats = () => {
  return Promise.resolve()
}

export const getCustomersBookDS = () => {
  return api.Get("/parameters/datastudio.content.iframe/customers-book")
}

export const getSummariesHermesTicketReport = (period) => {
  return misApi.Get(`/hermes/summaries-tickets/${period}`);
}