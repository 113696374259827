import React, { useState, useEffect } from 'react';
import { clone, get } from "lodash";
import qs from 'qs';
import { screeningSummaryPivotMeta, screeningStatesSummary } from '../../../../biz/metadatas/stats/screening/summaries';
import { FzTable } from '../../../fz/grid/table';
import { FzCard, FzSplitLine } from '../../../fz/layout/index';
import { periodColumn } from '../../../../components/table-standard';
import { StatsDetailHeader } from '../../../../statistics/stats-components';
import { setTTL } from '../../../../business/misc-functions';
import { FzNavSelect } from '../../../fz/nav';
import { formatMinutesSeconds } from '../../../fz/formatter/column-formatter';
import { getScreeningSummary } from '../../../../services/api/screening';

export function ScreeningSummaries (props) {
  
  const [requestState, setRequestState] = useState("idle")
  const [specificPeriod, setSpecificPeriod] = useState(null)
  const [urlChecked, setUrlChecked] = useState(false)
  
  const [filters, setFilters] = useState({
    period: "day"
  })

  useEffect(() => {
    if(urlChecked) {
      setUrlQueryString()
    }
  }, [filters])

  useEffect(() => {
    getQueryStringFromUrl()
  }, [])
  
  function getFilters() {
    const query = filters.period + qs.stringify(filters, { addQueryPrefix: true })
    return query
  }

  function handleChangePeriod(value){
    setSpecificPeriod(value)
  }

  function buildPeriodFilters(statisticsData) {
    let periods = {};
    if (statisticsData) {
      for (var i = 0; i < statisticsData.length; ++i) {
        let p = statisticsData[i].period;
        if (p && !periods[p]) {
          periods[p] = periodColumn(p);
        }
      }
    }
    return periods;
  };

  function setUrlQueryString() {
    const query = qs.stringify(filters, { addQueryPrefix: true })
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
    history.pushState({path:newurl},'',newurl);
    getScreeningReportSummaries()
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params: any = Object.fromEntries(urlSearchParams.entries());
    if(params.period) {
      setFilters(params)
    }else {
      setUrlQueryString()
    }
    setUrlChecked(true)
  }

  async function getScreeningReportSummaries() {
    const { ns } = props;
    setRequestState("requesting") 
    const summaryResults = await getScreeningSummary(getFilters());
    ns.set("screeningSummaries", summaryResults.statistics, setTTL(5))
    ns.set("screeningSummariesResponse", summaryResults)
    await ns.saveChange("filter_periods", buildPeriodFilters(summaryResults.statistics), null)
    setRequestState("idle")
  }

  async function pivotData(
    data,
    metas,
    pivotMetas,
    filterColumnName,
    filterValue,
    pivotColumnName,
    pivotValues,
    resultMetas,
  ) {
    let rows = ["notInitialized", "inAnalysis", "pended", "success", "rejected", "closed", "totalOutcomes", "total", 'queueTime', 'completionTime', 'analysisTime']
    
    let filteredData = data.filter((it) => {
      return it[filterColumnName] === filterValue
    });
    let pivotColumns
    if (filteredData.length > 0) {
      pivotColumns = filteredData.map(it => it.supplierInternalName)
    } 
    else {
      return null
    }
    
    if (filteredData.length) {
        rows.forEach((col, k) => {
          let v: { pivot: string, key: number } = { pivot: get(metas, [col, 'label'], null), key: k }
          pivotColumns.forEach(pivot => {
            v[pivot] = filteredData.filter(it => it[pivotColumnName] === pivot)
              .reduce((previous, it) => {
                if(col === "notInitialized" || col === "inAnalysis") {
                  return it[col]
                }
                return it[col] + previous
              }, 0)
            })
            
            //formatting analysis times
            if(v.pivot === 'Tempo médio em fila' || v.pivot === 'Tempo médio total' || v.pivot === 'Tempo médio em análise') {
              const keys = Object.keys(v)
              keys.forEach((key) => {
                if(key !== 'pivot' && key !== 'key') {
                  v[key] = formatMinutesSeconds(parseInt(v[key]))
                }
              })
            }
            pivotValues.push(v)
        })
    }
  
    pivotColumns.forEach(col => {
      let newCol = clone(pivotMetas["template"])
      newCol.path = col
      newCol.label = col
      newCol.tableProps.hidden = false
      resultMetas[col] = newCol
    })
  }

  function trClassName(row, rowIndex) {
    return rowIndex < 4 ? true : false;
  }

  function CustomColumn(cell, row, formatExtraData, idx, ns) {
    if ( cell === "Total finalizado" || cell === "Total de análises") {
      return (
        <strong> {cell} </strong>
      )
    }
    return cell
  }

  let summaries_total
  let summaries_pendent
  let summaries_notinitialized
  let summaries_mediatime
  let meta_total
  let meta_pendent
  let meta_notinitialized
  let meta_mediatime

  const { ns } = props;
  let filterValues = ns.getChanged("filter_periods") || [];
  let data = ns.get('screeningSummaries', [])
  let selectedPeriod = specificPeriod || Object.keys(filterValues || { "": "" })[0];
  let pivotMeta = screeningSummaryPivotMeta;
  let meta = screeningStatesSummary;
  let resultMetas = clone(pivotMeta);
  let pivotValues: { pivot: string, key: number }[] = [];

  pivotData(data, meta, pivotMeta, "period", selectedPeriod, "supplierInternalName", pivotValues, resultMetas)

  if (pivotValues && resultMetas) {
    
    summaries_total = pivotValues.filter(it => it.pivot === "Total de análises")
    summaries_pendent = pivotValues.filter(it =>  it.pivot === "Pendenciado" || it.pivot === "Validado" || it.pivot  === "Rejeitado" || it.pivot == "Fechado")
    summaries_notinitialized = pivotValues.filter(it => it.pivot === "Não iniciado" || it.pivot === "Em análise")
    summaries_mediatime = pivotValues.filter(it => it.pivot === "Tempo médio total" || it.pivot === "Tempo médio em análise" || it.pivot === "Tempo médio em fila")
    
    meta_total = clone(resultMetas)
    meta_total["pivot"].label = "Status da Triagens"

    meta_pendent = resultMetas

    meta_notinitialized = clone(resultMetas)
    //meta_notinitialized["pivot"].label = "Triagens em Fila" 

    meta_mediatime = clone(resultMetas)
    //meta_mediatime["pivot"].label = "Tempo médio das Análises"

  }

  return (
    <FzCard>
      <FzCard.Body>
        <FzCard>
          <FzCard.Heading fzStyle={{}}>
            <FzCard.Title> 
            <FzSplitLine> 
              <FzSplitLine.Left/>     
              <FzSplitLine.Right>
                <FzNavSelect 
                  value={filters.period}
                  options={[
                    { "code": "day", "description": "Daily" },
                    { "code": "week", "description": "Weekly" },
                    { "code": "month", "description": "Monthly" },
                  ]}
                  onChange={(value) => {
                    setFilters({...filters, period: value})
                    setSpecificPeriod(null)
                  }}
                /> 
              </FzSplitLine.Right>
            </FzSplitLine>
            <br></br>
            <StatsDetailHeader 
              title="Summaries Screening"
              stats= {ns.get("screeningSummariesResponse")} 
              periods={filterValues} 
              currPeriod={selectedPeriod} 
              onChange={handleChangePeriod} 
            /> 
            </FzCard.Title>
          </FzCard.Heading>
        </FzCard>
        <FzCard>
          <FzCard.Body>
            <FzTable
              ns={ ns }
              data={summaries_total}
              metas={meta_total}
              exportDownload={false}
              clipboard={false}
              customColumnFormatters={{
                pivot: (cell, row, formatExtraData, idx, ns) => CustomColumn(cell, row, formatExtraData, idx, ns)
              }}
            />
            <br></br>
            <FzTable
              ns={ ns }
              data={summaries_pendent}
              metas={meta_pendent}
              exportDownload={false}
              clipboard={false}
              rowDisabled={trClassName}
            />
            <br></br>
            <FzTable
              ns={ ns }
              data={summaries_notinitialized}
              metas={meta_notinitialized}
              exportDownload={false}
              clipboard={false}
              rowDisabled={trClassName}
              customColumnFormatters={{
                pivot: (cell, row, formatExtraData, idx, ns) => CustomColumn(cell, row, formatExtraData, idx, ns)
              }}
            /> 
            <br></br>
            <FzTable
              ns={ ns }
              data={summaries_mediatime}
              metas={meta_mediatime}
              exportDownload={false}
              clipboard={false}
              rowDisabled={trClassName}
            />
          </FzCard.Body>
        </FzCard>
      </FzCard.Body>
    </FzCard>
  )
}