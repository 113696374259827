/* global gapi */
import React, { Component } from 'react';
import Home from './home';
import { StatsConsole } from './ui/pages/statistics/stats-console';
import ApplicationListView from './ui/blocks/mis-applications/applications';
import { Login, Logout } from './login';
import About from './about';
import { Route, Redirect, Router, Switch } from 'react-router-dom';
import api from './api/client-api';
import { withNS, createStoreNS } from './scripts/state/redux_ns';

import {
  readFromLocalStorage,
  saveStateToLocalStorage,
} from './scripts/utils/state-helpers';
import { SupplierApplication } from './supplier/application-detail-supplier';
import NewCampaign from './campaigns/new-campaign';
import { SalesFindApplication } from './ui/blocks/find-applications';
import { CustomerConsole } from './crm/customer-console';
import SalesConvertCampaign from './crm/campaign-convert';
import { SalesEditApplication } from './crm/edit-application';
import CampaignConsole from './campaigns/campaign-console';
import { APIsConsole } from '../src/ui/pages/integration-console';
import { AgentsConsole } from './ui/blocks/agents/console';
import { CronConsole } from './ui/blocks/cron/console';
import { AggregationsConsole } from './ui/pages/aggregations/console';
import { CronTask } from './ui/blocks/cron/task';
import { AgentHome } from '../src/ui/blocks/agents/agent-home';
import { ServersConsole } from '../src/ui/blocks/api-servers/console';
import { AdminSuppliers } from './admin-suppliers/suppliers-console';
import { AdminMessages } from './admin-messages/messages-console';
import { EditSupplier } from './admin-suppliers/edit-supplier';
import { EditMessageRule } from './admin-messages/edit-message';
import { UsersConsole } from './ui/blocks/admin-users/users-console';
import { EditUser } from './ui/blocks/admin-users/user-edit';
import { EditSystemParam } from './ui/blocks/parameters-service/edit-parameters-service';
import { AffiliateConsole } from './ui/blocks/affiliate/affiliate-console';
import { CreditRisk } from './ui/pages/credit-risk';
import { EmailTemplateGo } from './ui/pages/email-template-go';
import { EmailHistory } from './ui/pages/messages/emailHistory';
import { AcceptedBids } from './ui/pages/supplier/accepted-applications';
import { ApplicationsWithBids } from './ui/pages/supplier/bids-posted-applications';
import { SupplierSentApplications } from './ui/pages/supplier/supplier-sent-applications';
import { RejectAndPayOutApplication } from './ui/pages/supplier/rejectpay-application';
import { BiddableApplications } from './ui/pages/supplier/biddable-applications';
import { Screening } from './ui/pages/screening/screening-index';
import { ManagerScreening } from './ui/pages/screening/manager-screening';
import { InternalPendency } from './ui/pages/screening/internal-pendency';
import UpsellList from './ui/pages/upsellBanner/list';
import { HermesUsersList } from './ui/pages/hermes/users/usersList';
import { HermesQueuesList } from './ui/pages/hermes/queues/usersQueues';
import { HermesReports } from './ui/pages/hermes/reports/index';

import ContractList from './ui/pages/contract/list';

import InputLayoutList from './ui/pages/inputlayout/list';
import InputLayoutAdd from './ui/pages/inputlayout/add';
import InputLayoutEdit from './ui/pages/inputlayout/edit';
import InputLayoutUpload from './ui/pages/inputlayout/upload';
import InputLayoutListFiles from './ui/pages/inputlayout/listFiles'
import InputLayoutListRows from './ui/pages/inputlayout/listRows'

import { MainLayout } from './main-layout';
import { Provider } from 'react-redux';

import 'moment/locale/pt-br';

import { OCRDocuments } from './ui/pages/ocr-documents';
import { CampaignsInfo } from './ui/pages/campaigns/campaigns-info';
import { FindCustomer } from './ui/blocks/crm/find-customer';
import { SystemParams } from './ui/pages/system-param';
import { SystemFzScore } from './ui/pages/system-fzScore';
import { createBrowserHistory } from 'history';

import { ToastProvider } from './hooks/ToastProvider';
import { ScreeningRules } from './ui/pages/screening/rules/ScreeningRules';
import { PartnerPriority } from './ui/blocks/parameters-service/partner/partner-priority/partner-priority';
import { FiltersManager } from './ui/blocks/parameters-service/partner/filtersManager/filters-manager';
import { BlocklistSearch } from './ui/pages/blocklist/blocklist-search';
import { BlocklistReport } from './ui/pages/blocklist/blocklist-report';
import AddUpsell from './ui/pages/upsellBanner/add';
import EditUpsell from './ui/pages/upsellBanner/edit';
import { HermesUserDetails } from './ui/pages/hermes/users/userDetails';
import { ReprocessingSearch } from './ui/pages/reprocessing/reprocessing-search';
import { ReprocessingView } from './ui/pages/reprocessing/reprocessing-individual';
import { QueueDetails } from './ui/pages/hermes/queues/queueDetails';
import { PickUpHermes } from './ui/pages/hermes/pickup/pickupHermes';
import { AttendenceConsoleHermes } from './ui/pages/hermes/pickup/attendenceConsoleHermes';
import Affiliate from './ui/pages/affiliate/list';
import EditAffiliate from './ui/pages/affiliate/edit';
import { URLShortener } from './ui/pages/urlShortener';
import FormPack from './ui/pages/forms';
import OCRModelPack from './ui/pages/ocrModels';
import PathnamePack from './ui/pages/pathname';


export const browserHistory = createBrowserHistory({ basename: '/' });

const addReduxDevTool = () => {
  return (
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        let nextPathname = '';

        if (
          location.pathname != '/' ||
          location.pathname !== '' ||
          location.search !== ''
        ) {
          nextPathname = `?nextPathname=${location.pathname}${location.search}`;
        }

        return rest.requireAuth === undefined || rest.requireAuth() === true ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to={`/login${nextPathname}`} />
        );
      }}
    />
  );
};

var auth2;

class App extends Component {
  constructor(props) {
    super(props);
    this.store = createStoreNS({
      sharedNSName: 'COF',
      preload: readFromLocalStorage(),
      enhancer: addReduxDevTool(),
    });
    this.store.subscribe(() => saveStateToLocalStorage(this.store.getState()));

    this.requireAuth = this.requireAuth.bind(this);
    this.requireMISorAdvisor = this.requireMISorAdvisor.bind(this);
    this.requireMISorAdvisorOrScreener =
      this.requireMISorAdvisorOrScreener.bind(this);
    this.requireAdmin = this.requireAdmin.bind(this);
    this.requireSupplier = this.requireSupplier.bind(this);
    this.requireSales = this.requireSales.bind(this);
    this.requireFZInternal = this.requireFZInternal.bind(this);
    this.requireAdvisor = this.requireAdvisor.bind(this);
    this.requireMISorSupplier = this.requireMISorSupplier.bind(this);
    this.requireAdvisorOrAdmin = this.requireAdvisorOrAdmin.bind(this);
  }

  requireMISorAdmin() {
    if (api.getAuth(auth2) == null) {
      return this.requireAuth();
    }
    if (api.isUserInRole('mis') || api.isUserInRole('admin')) {
      return true;
    }
    return false;
  }

  requireAuth() {
    if (api.getAuth(auth2) == null) {
      return false;
    }
    return true;
  }

  requireAdvisorOrAdmin() {
    if (api.getAuth(auth2) == null) {
      return this.requireAuth();
    }
    if (api.isUserInRole('advisor') || api.isUserInRole('admin')) {
      return true;
    }
    return false;
  }

  requireAdmin() {
    if (api.getAuth(auth2) == null) {
      return this.requireAuth();
    }
    if (!api.isUserInRole('admin')) {
      return false;
    }
    return true;
  }
  requireAsgardian() {
    if (api.getAuth(auth2) == null) {
      return this.requireAuth();
    }
    if (api.isUserInRole('admin') && api.isAsgardianUser()) {
      return true;
    }
    return false;
  }
  requireScreener() {
    if (api.getAuth(auth2) == null) {
      return this.requireAuth();
    }
    if (!api.isUserInRole('screener')) {
      return false;
    }
    return true;
  }
  requireMISorAdvisor() {
    if (api.getAuth(auth2) == null) {
      return this.requireAuth();
    }
    if (api.isUserInRole('mis') || api.isUserInRole('advisor')) {
      return true;
    }
    return false;
  }
  requireMISorAdvisorOrScreener() {
    if (api.getAuth(auth2) == null) {
      return this.requireAuth();
    }
    if (
      api.isUserInRole('mis') ||
      api.isUserInRole('advisor') ||
      api.isUserInRole('screener')
    ) {
      return true;
    }
    return false;
  }
  requireSales() {
    if (api.getAuth(auth2) == null) {
      return this.requireAuth();
    }
    if (!api.isUserInRole('sales') && !api.isUserInRole('advisor')) {
      return false;
    }
    return true;
  }
  requireSupplier() {
    if (api.getAuth(auth2) == null) {
      return this.requireAuth();
    }
    if (!api.isUserInRole('supplier')) {
      return false;
    }
    return true;
  }
  requireFZInternal() {
    if (api.getAuth(auth2) == null) {
      return this.requireAuth();
    }
    if (api.isUserInRole('supplier')) {
      return false;
    }
    return true;
  }
  requireMISorSupplier() {
    if (api.getAuth(auth2) == null) {
      return this.requireAuth();
    }
    if (
      !api.isUserInRole('supplier') &&
      !api.isUserInRole('mis') &&
      !api.isUserSales()
    ) {
      return false;
    }
    return true;
  }
  requireAdvisor() {
    if (api.getAuth(auth2) == null) {
      return this.requireAuth();
    }
    if (!api.isUserInRole('advisor')) {
      return false;
    }
    return true;
  }

  requireFranchisee() {
    if (api.getAuth(auth2) == null) {
      return this.requireAuth();
    }
    if (!api.isUserInRole('franchisee')) {
      return false;
    }
    return true;
  }

  requireAffiliateOrAdmin() {
    if (api.getAuth(auth2) == null) {
      return this.requireAuth();
    }
    if (!api.isUserInRole('admin') && !api.isUserInRole('affiliate')) {
      return false;
    }
    return true;
  }

  render() {
    return (
      <Provider store={this.store}>
        <Router history={browserHistory}>
          <ToastProvider>
            <MainLayout history={browserHistory}>
              <Switch>
                {/* Admin */}
                <PrivateRoute
                  exact
                  path={'/'}
                  component={Home}
                  requireAuth={this.requireAuth}
                />
                <PrivateRoute
                  exact
                  path={'/login'}
                  component={withNS(Login, { sharedNS: 'COF' })}
                />
                <PrivateRoute
                  exact
                  path={'/about'}
                  component={withNS(About, { sharedNS: 'COF' })}
                  fzApi={api}
                  requireAuth={this.requireAuth}
                />
                <PrivateRoute exact path={'/logout'} component={Logout} />

                <PrivateRoute
                  exact
                  path={'/stats'}
                  component={withNS(StatsConsole, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireMISorAdvisorOrScreener}
                />
                <PrivateRoute
                  exact
                  path={'/stats/:tab'}
                  component={withNS(StatsConsole, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireMISorAdvisorOrScreener}
                />
                <PrivateRoute
                  exact
                  path={'/stats/:tab/:subItem'}
                  component={withNS(StatsConsole, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireMISorAdvisorOrScreener}
                />
                <PrivateRoute
                  exact
                  path={'/stats/:tab/:subItem/:period'}
                  component={withNS(StatsConsole, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireMISorAdvisorOrScreener}
                />

                <PrivateRoute
                  exact
                  path={'/affiliate'}
                  component={withNS(AffiliateConsole, { sharedNS: 'COF' })}
                  requireAuth={this.requireAffiliateOrAdmin}
                />
                <PrivateRoute
                  exact
                  path={'/creditRisk'}
                  component={withNS(CreditRisk, { api: api, sharedNS: 'COF' })}
                  requireAuth={this.requireMISorAdmin}
                />
                <PrivateRoute
                  exact
                  path={'/parameters/fzScore'}
                  component={withNS(SystemFzScore, { sharedNS: 'COF' })}
                  requireAuth={this.requireAdmin}
                />

                <PrivateRoute
                  exact
                  path={'/applications'}
                  fzApi={api}
                  component={withNS(ApplicationListView, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireMISorAdvisor}
                />
                <PrivateRoute
                  exact
                  path={'/applications/:applicationId'}
                  component={withNS(SalesEditApplication, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireMISorAdvisor}
                />

                <PrivateRoute
                  exact
                  path={'/customer'}
                  fzApi={api}
                  component={withNS(FindCustomer, {
                    sharedNS: 'COF',
                    category: 'receptive',
                  })}
                  requireAuth={this.requireAdmin}
                />
                <PrivateRoute
                  exact
                  path={'/customer/:customerId'}
                  component={withNS(CustomerConsole)}
                  requireAuth={this.requireAdmin}
                />

                <PrivateRoute
                  exact
                  path={'/admusers/:role/:userId'}
                  fzApi={api}
                  component={withNS(EditUser, { api: api, sharedNS: 'COF' })}
                  requireAuth={this.requireAdmin}
                />
                <PrivateRoute
                  exact
                  path={'/admusers/:role'}
                  fzApi={api}
                  component={withNS(UsersConsole, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireAdmin}
                />

                <PrivateRoute
                  exact
                  path={'/admsuppliers'}
                  fzApi={api}
                  component={withNS(AdminSuppliers, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireFZInternal}
                />
                <PrivateRoute
                  exact
                  path={'/admsuppliers/:supplierId'}
                  component={withNS(EditSupplier, { api: api })}
                  requireAuth={this.requireFZInternal}
                />

                <PrivateRoute
                  exact
                  path={'/integration'}
                  component={withNS(APIsConsole, { api: api, sharedNS: 'COF' })}
                  requireAuth={this.requireMISorSupplier}
                />
                <PrivateRoute
                  exact
                  path={'/integration/:page'}
                  component={withNS(APIsConsole, { api: api, sharedNS: 'COF' })}
                  requireAuth={this.requireMISorSupplier}
                />
                <PrivateRoute
                  exact
                  path={'/integration/:page/:channel'}
                  component={withNS(APIsConsole, { api: api, sharedNS: 'COF' })}
                  requireAuth={this.requireMISorSupplier}
                />
                <PrivateRoute
                  exact
                  path={'/integration/:page/:channel/:period'}
                  component={withNS(APIsConsole, { api: api, sharedNS: 'COF' })}
                  requireAuth={this.requireMISorSupplier}
                />
                <PrivateRoute
                  exact
                  path={'/integration/:page/:channel/:period/:supplier'}
                  component={withNS(APIsConsole, { api: api, sharedNS: 'COF' })}
                  requireAuth={this.requireMISorSupplier}
                />

                {/* IT */}
                <PrivateRoute
                  exact
                  path={'/manager/servers'}
                  component={withNS(ServersConsole, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireAdmin}
                />
                <PrivateRoute
                  exact
                  path={'/manager/cron'}
                  component={withNS(CronConsole, { api: api, sharedNS: 'COF' })}
                  requireAuth={this.requireAdmin}
                />
                <PrivateRoute
                  exact
                  path={'/manager/cron/:taskName'}
                  component={withNS(CronTask, { api: api, sharedNS: 'COF' })}
                  requireAuth={this.requireAdmin}
                />
                <PrivateRoute
                  exact
                  path={'/manager/aggregations'}
                  component={withNS(AggregationsConsole, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireAdmin}
                />
                <PrivateRoute
                  exact
                  path={'/manager/agents'}
                  component={withNS(AgentsConsole, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireAdmin}
                />
                <PrivateRoute
                  exact
                  path={'/manager/agents/:agent'}
                  component={withNS(AgentHome, { api: api, sharedNS: 'COF' })}
                  requireAuth={this.requireMISorSupplier}
                />
                <PrivateRoute
                  exact
                  path={'/manager/agents/:agent/:queryType'}
                  component={withNS(AgentHome, { api: api, sharedNS: 'COF' })}
                  requireAuth={this.requireMISorSupplier}
                />
                <PrivateRoute
                  exact
                  path={'/manager/agents/:agent/:queryType/:queueName'}
                  component={withNS(AgentHome, { api: api, sharedNS: 'COF' })}
                  requireAuth={this.requireMISorSupplier}
                />

                <PrivateRoute
                  exact
                  path={'/campaigns'}
                  component={withNS(CampaignConsole, {
                    ...this.props,
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireMISorAdvisor}
                />
                <PrivateRoute
                  exact
                  path={'/campaigns/prepare'}
                  component={withNS(CampaignsInfo, {
                    ...this.props,
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireMISorAdvisor}
                />
                <PrivateRoute
                  exact
                  path={'/campaigns/:campaign/:state'}
                  component={withNS(CampaignConsole, {
                    ...this.props,
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireMISorAdvisor}
                />
                <PrivateRoute
                  exact
                  path={'/new-campaign'}
                  component={withNS(NewCampaign, { api: api, sharedNS: 'COF' })}
                  requireAuth={this.requireAdmin}
                />

                <PrivateRoute
                  exact
                  path={'/admmessages'}
                  fzApi={api}
                  component={withNS(AdminMessages, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireFZInternal}
                />
                <PrivateRoute
                  exact
                  path={'/admmessages/:ruleId'}
                  component={withNS(EditMessageRule, { api: api })}
                  requireAuth={this.requireFZInternal}
                />
                <PrivateRoute
                  exact
                  path={'/emailTemplate'}
                  component={withNS(EmailTemplateGo, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireAdmin}
                />
                <PrivateRoute
                  exact
                  path={'/emailHistory'}
                  component={withNS(EmailHistory, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireAdmin}
                />

                <PrivateRoute
                  exact
                  path={'/url-shortener'}
                  component={withNS(URLShortener, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireAdmin}
                />

                <PrivateRoute
                  exact
                  path={'/parameters/config'}
                  component={withNS(SystemParams, { sharedNS: 'COF' })}
                  requireAuth={this.requireAdmin}
                />
                <PrivateRoute
                  exact
                  path={'/parameters/config/:domainName'}
                  component={withNS(EditSystemParam, { sharedNS: 'COF' })}
                  requireAuth={this.requireAdmin}
                />
                <PrivateRoute
                  exact
                  path={'/parameters/partner-priority'}
                  component={withNS(PartnerPriority, {
                    ...this.props,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireAdmin}
                />
                <PrivateRoute
                  exact
                  path={'/parameters/filters-manager'}
                  component={withNS(FiltersManager, { api, sharedNS: 'COF' })}
                  requireAuth={this.requireAdmin}
                />

                {/* Advisor & Sales */}
                <PrivateRoute
                  exact
                  path={'/sales/manual'}
                  component={withNS(SalesFindApplication, {
                    api: api,
                    sharedNS: 'COF',
                    category: 'manual-ative',
                  })}
                  requireAuth={this.requireSales}
                />
                <PrivateRoute
                  exact
                  path={'/sales/in'}
                  component={withNS(SalesFindApplication, {
                    api: api,
                    sharedNS: 'COF',
                    category: 'receptive',
                  })}
                  requireAuth={this.requireSales}
                />

                <PrivateRoute
                  exact
                  path={'/sales/out/customer/:customerId'}
                  component={withNS(CustomerConsole, {
                    basePath: '/sales/out',
                  })}
                  requireAuth={this.requireSales}
                />
                <PrivateRoute
                  exact
                  path={'/sales/in/customer/:customerId'}
                  component={withNS(CustomerConsole, { basePath: '/sales/in' })}
                  requireAuth={this.requireSales}
                />
                <PrivateRoute
                  exact
                  path={'/sales/manual/customer/:customerId'}
                  component={withNS(CustomerConsole, {
                    basePath: '/sales/manual',
                  })}
                  requireAuth={this.requireSales}
                />

                <PrivateRoute
                  exact
                  path={'/sales/:applicationId'}
                  component={withNS(SalesEditApplication, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireSales}
                />
                <PrivateRoute
                  exact
                  path={'/sales/campaign/:campaignId'}
                  component={withNS(SalesConvertCampaign, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireSales}
                />

                {/* Supplier*/}

                <PrivateRoute
                  exact
                  path={'/suppliers/biddable'}
                  component={withNS(BiddableApplications, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireSupplier}
                />
                <PrivateRoute
                  exact
                  path={'/suppliers/accepted'}
                  component={withNS(AcceptedBids, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireSupplier}
                />
                <PrivateRoute
                  exact
                  path={'/suppliers/bids'}
                  component={withNS(ApplicationsWithBids, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireSupplier}
                />
                <PrivateRoute
                  exact
                  path={'/suppliers/biddable-sent'}
                  component={withNS(SupplierSentApplications, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireSupplier}
                />
                <PrivateRoute
                  exact
                  path={'/suppliers/change-bid'}
                  component={withNS(RejectAndPayOutApplication, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireSupplier}
                />
                <PrivateRoute
                  exact
                  path={'/suppliers/:applicationId'}
                  fzApi={api}
                  component={withNS(SupplierApplication, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireSupplier}
                />

                {/* SCreening*/}
                <PrivateRoute
                  exact
                  path={'/screening/rules'}
                  component={withNS(ScreeningRules, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireAdmin}
                />
                <PrivateRoute
                  exact
                  path={'/screening/customer'}
                  component={withNS(ManagerScreening, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireScreener}
                />
                <PrivateRoute
                  exact
                  path={'/screening/internal-pendency'}
                  component={withNS(InternalPendency, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireScreener}
                />
                <PrivateRoute
                  exact
                  path={'/screening/:step'}
                  component={withNS(Screening, { api: api, sharedNS: 'COF' })}
                  requireAuth={this.requireScreener}
                />
                <PrivateRoute
                  exact
                  path={'/screening/:step/:documentType'}
                  component={withNS(Screening, { api: api, sharedNS: 'COF' })}
                  requireAuth={this.requireScreener}
                />

                {/* OCR */}
                <PrivateRoute
                  exact
                  path={'/ocr/documents'}
                  component={withNS(OCRDocuments, {
                    ...this.props,
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireAdmin}
                />

                {/* Redirect & 403 */}
                <Redirect from="/sales" to="/sales/out" />
                <Redirect from="/admusers" to="/admusers/sales" />

                {/* Blocklist */}
                <PrivateRoute
                  exact
                  path={'/blocklist/report'}
                  component={withNS(BlocklistReport, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireAdmin}
                />
                <PrivateRoute
                  exact
                  path={'/blocklist/search'}
                  component={withNS(BlocklistSearch, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireAdvisorOrAdmin}
                />

                {/* Hermes */}
                <PrivateRoute
                  exact
                  path={'/hermes/users'}
                  component={withNS(HermesUsersList, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                />
                <PrivateRoute
                  exact
                  path={'/hermes/users/:userId'}
                  component={withNS(HermesUserDetails, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                />
                <PrivateRoute
                  exact
                  path={'/hermes/queues'}
                  component={withNS(HermesQueuesList, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                />
                <PrivateRoute
                  exact
                  path={'/hermes/queue/'}
                  component={withNS(QueueDetails, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                />
                <PrivateRoute
                  exact
                  path={'/hermes/queue/:queueId'}
                  component={withNS(QueueDetails, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                />
                <PrivateRoute
                  exact
                  path={'/hermes/tickets'}
                  component={withNS(PickUpHermes, {
                    api: api,
                    sharedNS: 'COF',
                    category: 'activeHermes',
                  })}
                  requireAuth={this.requireAdvisor}
                />
                <PrivateRoute
                  exact
                  path={'/hermes/tickets/:id'}
                  component={withNS(AttendenceConsoleHermes, {
                    basePath: '/hermes/tickets',
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireAdvisor}
                />
                <PrivateRoute
                  exact
                  path={'/hermes/reports/:tab'}
                  component={withNS(HermesReports, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireAdvisorOrAdmin}
                />

                {/* Bifrost */}
                <PrivateRoute
                  exact
                  path={'/manager/reprocessing/search'}
                  component={withNS(ReprocessingSearch, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireAsgardian}
                />
                <PrivateRoute
                  exact
                  path={'/manager/reprocessing/:id'}
                  component={withNS(ReprocessingView, {
                    api: api,
                    sharedNS: 'COF',
                  })}
                  requireAuth={this.requireAsgardian}
                />

                {/* Upsell Banners dory */}
                <PrivateRoute
                  exact
                  path={'/upsell/list'}
                  component={withNS(UpsellList)}
                />
                <PrivateRoute
                  exact
                  path={'/upsell/add'}
                  component={withNS(AddUpsell)}
                />
                <PrivateRoute
                  exact
                  path={'/upsell/edit/:upsellId'}
                  component={withNS(EditUpsell)}
                />

                {/* Pathname dory */}
                <PrivateRoute
                  exact
                  path={'/pathname/:id?'}
                  component={withNS(PathnamePack)}
                />

                {/* Affiliated dory */}
                <PrivateRoute
                  exact
                  path={'/affiliates'}
                  component={withNS(Affiliate)}
                />
                <PrivateRoute
                  exact
                  path={'/affiliates/:affiliateName'}
                  component={withNS(EditAffiliate)}
                />

                {/* Form dory */}
                <PrivateRoute
                  exact
                  path={'/forms'}
                  component={withNS(FormPack)}
                />
                <PrivateRoute
                  exact
                  path={'/form/:id?'}
                  component={withNS(FormPack)}
                />

                {/* Contract */}
                <PrivateRoute exact path={"/contracts/list"} component={withNS(ContractList)} />

                {/* Input Layout */}
                <PrivateRoute exact path={"/input-layout/list"} component={withNS(InputLayoutList)} />
                <PrivateRoute exact path={"/input-layout/add"} component={withNS(InputLayoutAdd)} />
                <PrivateRoute exact path={"/input-layout/edit/:inputLayoutId"} component={withNS(InputLayoutEdit)} />
                <PrivateRoute exact path={"/input-layout/upload"} component={withNS(InputLayoutUpload)} />
                <PrivateRoute exact path={"/input-layout/list-files"} component={withNS(InputLayoutListFiles)} />
                <PrivateRoute exact path={"/input-layout/list-rows/:id"} component={withNS(InputLayoutListRows)} />


                {/* OCR Models */}
                <PrivateRoute
                  exact
                  path={'/ocr/models/:id?'}
                  component={withNS(OCRModelPack)}
                />

              </Switch>
            </MainLayout>
          </ToastProvider>
        </Router>
      </Provider>
    );
  }
}

export default App;
