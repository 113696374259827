import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { getSuppliers } from '../../../../scripts/utils/cache-helpers'

import { UnicoReports as UnicoReportsContainer } from '../../../../Containers/UnicoReports'
import api from '../../../../api/client-api'
import { 
  getAnalyticsUnicoReports, 
  getSummariesUnicoReport,
} from '../../../../api/formalization'

function UnicoReports({ ns }) {
  const [typeView, setTypeView] = useState('')
  const [data, setData] = useState({ })
  const [showTost, setShowTost] = useState(false)
  const [suppliers, setSupplier] = useState([])
  
  useEffect(() => {
    ns.saveChange("from", moment().subtract(10, "days").startOf("day").toISOString())
    ns.saveChange("to", moment().endOf("day").toISOString())
    
    getSupplierHandler()
  }, [])

  const getSupplierHandler = async () => {
    const suppliersData = await getSuppliers(ns)
    setSupplier(suppliersData)
  }
  
  const handlePage = (pageType) => {
    setTypeView(pageType)
    setData({})
  }

  const getAnalyticsData = async (from, to) => {
    try {
      const res = await getAnalyticsUnicoReports(
        moment(from).toISOString(),
        moment(to).toISOString(),
      )
      setData(res)
    } catch (error) {
      setShowTost(true)
    }
  }

  const getSummariesData =  async (period) => {
    try {
      const response = await getSummariesUnicoReport(period)
      setData(response)
    } catch (error) {
      setShowTost(true)
    }
  }

  const handleSearchReport = (period) => {
    setData({})
    const {from = null, to = null } = ns.getChanged()
    if(typeView === "analytics" && from && to) {
      return getAnalyticsData(from, to)
    }
    return getSummariesData(period)
  }

  const { role = 'no-role' } = api.getCurrentUser()
  const filters = ns.getShared("filters") || ns.getChanged()
  const handleTostClose = () => setShowTost(false)

  return (
    <UnicoReportsContainer
      ns={ns}
      handlePage={handlePage}
      typeView={typeView}
      handleSearchReport={handleSearchReport}
      data={data}
      role={role}
      filters={filters}
      handleTostClose={handleTostClose}
      showTost={showTost}
      suppliers={suppliers}
    />
  )
}

export { UnicoReports }
