import { useState } from 'react';
import { FzButton } from '../../../fz/form';
import { FzCol, FzRow } from '../../../fz/layout';
import { FzToast } from '../../../fz/form/notification/toast';
import { getHermesCurrent, getHermesPick, finalizeTicketAttendance } from '../../../../services/api/hermes';
import { FzCard } from '../../../fz/layout/FzCard';
import { getByID } from '../../../../api/applications';
import { getUserById } from '../../../../api/users';


export const PickUpHermes = ({ ns, history }) => {
  const [show, setShow] = useState(false);
  const [headerMessageTost, setHeaderMessageTost] = useState('');
  const [bodyMessageTost, setBodyMessageTost] = useState('');
  const [colorTost, seColorTost] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const processCustomerData = (ticket) => {
    ns.unset("ticket");
    ns.setShared("crm.category", "activeHermes")
    ns.setShared("sales.active.channel", "phone")
    ns.setShared('ticket', ticket);

    showResultToast('Hermes', 'Atendimento iniciado', 'success');
    setIsLoading(false);
    
    let newPath = ns.namespace + '/' + ticket._id;
    history.push(newPath);
  };

  const getNextTicket = async () => {   
    try {
      setIsLoading(true);
      let ticket = await getHermesCurrent();
      if (ticket && ticket.status === 204) { 
        ticket = await getHermesPick();
        if (ticket && ticket.status === 204) {
          showResultToast(
            'Filas vazias!',
            'Não há nenhum Ticket disponível para o seu usuário!',
            'warning'
          );
          setIsLoading(false);
          return;
        } 
      }
      
      if (ticket) {
        const application = await getByID(ticket.applicationId);
        if (application.customerId !== "000000000000000000000000") {
          const customer = await getUserById(application.customerId);
          ticket = {
            ...ticket,
            application: application,
            customer: customer,
          };
          processCustomerData(ticket);
          setIsLoading(false);
          return;
        } else {
          await finalizeTicketAttendance(ticket._id, { 
            outcome: "fz-incident-application-excluded", 
            closedReason: "closeTicketWithoutApplicationId", 
            contacts: [],
          })
        }
      }
    } catch (error) {
      let err: $TsFixMe = error
      console.log(err);
      if (err?.response?.status === 401 || err?.response?.status === 400) {
        showResultToast(
          'Erro Hermes',
          'Verifique se seu usuário tem alguma fila vinculada!',
          'warning'
        );
        setIsLoading(false);
      } else {
        showResultToast(
          'Erro Hermes',
          'Erro ao pegar um Ticket!',
          'danger'
        );
        setIsLoading(false);
      }
    }
  }


  const handleClose = () => {
    setShow(false);
  };

  const showResultToast = (header, message, color) => {
    setHeaderMessageTost(header);
    setBodyMessageTost(message);
    seColorTost(color);
    setShow(true);
  };

  return (
    <>
      <FzCard.Header>
        <FzCard.Title>
          <h3> Hermes - Iniciar atendimento</h3>
        </FzCard.Title>
      </FzCard.Header>
      <div
        style={{
          margin: '1rem',
          padding: '2rem',
          borderRadius: '1rem',
          border: 'solid 2px lightgrey',
          background: 'lightgrey',
        }}
      >
        <FzRow>
          <FzCol span={8}>
            <FzButton
              onClick={() => getNextTicket()}
              disabled={isLoading}
              bsStyle="info"
            >
              Próximo Ticket
            </FzButton>
            {isLoading && (
              <div style={{ paddingTop: '1rem' }}>
                Buscando o próximo ticket aguarde, por gentileza...
              </div>
            )}
          </FzCol>
        </FzRow>
      </div>

      <FzToast
        fzStyle={colorTost}
        close={handleClose}
        show={show}
        delay={5000}
        headerMessageTost={headerMessageTost}
        bodyMessageTost={bodyMessageTost}
        autoHide={false}
      />
    </>
  );
};
