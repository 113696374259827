'use strict';

import { finanVcApi } from '../../../api/client-api';
import {
  FinanvcRequestInterface,
  FinanvcResponseInterface,
} from '../../../biz/metadatas/url-shortener-metadata';

export const postURLShortener = async (
  payload: FinanvcRequestInterface
): Promise<any | FinanvcResponseInterface> => {
  console.log(finanVcApi)
  return finanVcApi.Post('admin/url', payload);
};
