import { bureauApi, misApi } from "../../../api/client-api";
import qs from 'qs'

export const getAnalyticsBoaVistaReports = async (
  filter: any
): Promise<any> => {
  return bureauApi.Get(`proofdocuments/analytics?${qs.stringify(filter)}`);
}

export const getValidatorsBoaVistaReports = async (
  period: string
): Promise<any> => {
  return misApi.Get(`validators/bureau/${period}`)
}

export const getTrace = async (appId, service): Promise<any> => { //chack later
  try {
    const serviceUrl = `/trace/${appId}/${service}`;
    const response = await bureauApi.Get(serviceUrl);
    return response
  } catch (err) {
    return { 
      message: "Não foi possível localizar um trace para esse serviço", 
      //error: JSON.stringify(err) 
    };
  }
}

export const getCarModels = async (appId, carPlate): Promise<any> => {
  const serviceUrl = 'vehicle/license-plate'
  const body = {
    applicationId: appId,
    licensePlate: carPlate
  }

  return bureauApi.Post(serviceUrl, body)
}

export const getCarData = async (appId, carPlate, model): Promise<any> => {
  const serviceUrl = 'vehicle/license-plate/model'
  const body = {
    applicationId: appId,
    licensePlate: carPlate,
    model: model
  }

  return bureauApi.Post(serviceUrl, body)
}

export const upsertBlocklist = async (body): Promise<any> => {
  return await bureauApi.Post('blocklist/update', body)
}

export const disableBlocklist = async (body): Promise<any> => {
  return await bureauApi.Put('blocklist/disable', body)
}

export const getAllBlocklist = async (filter): Promise<any> => {
  let url = `blocklist?startDate=${new Date(filter.startDate).toISOString()}&endDate=${new Date(filter.endDate).toISOString()}`
  return bureauApi.Get(url)
}

export const getOneBlocklist = async (filter): Promise<any> => {
  let url = `blocklist/entry?type=${filter.type}&value=${filter.value}`
  return await bureauApi.Get(url)
}

export const getParameterFzScore = async (product): Promise<string[]> => {
  let url = `score/parameters/${product}/fzscore` 
  return await bureauApi.Get(url)
}