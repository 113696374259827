
import { misApi } from "../../../api/client-api";

export const getAnalyticsHSReports = async (
  from: string,
  to: string): Promise<any> => {
  return [];
}

export const getValidatorsHSReports = async (period: string): Promise<any> => {
  return await misApi.Get(`validators/hs/${period}`)
}
