import api from '../../../api/client-api';
import { screeningApi, misApi } from '../../../api/client-api';

import {
  DocumentsToInsertRequest,
  FieldsOverridesType,
  PostponeAnalysisResponse,
  ScreeningResponse,
  IScreeningRules,
  setDocAsPendingBady,
  setTheReasonForRejection,
  ScreeningRulesResponse,
  cloneDocumentData,
  actionDocumentData,
  PostponedScreeningResponse,
  AnalyticReportResponse,
  PrioritizedBidsReportResponse,
  OverwriteDocumentReportAnalyticsResponse,
  Rules
} from "./types";
import qs from 'qs'

export const getFormalizationDocumentUrl = (
  applicationId: string,
  bidId: string,
  documentType: string,
  fmt: string,
  userDocumentId: string): string => {
  const path = `applications/${applicationId}/bids/${bidId}/formalization/documents/${documentType}?`;

  const params = [['token', api.getAuth()]];
  if (fmt !== "") params.push(['fmt', fmt]);
  if (userDocumentId) params.push(['userDocumentId', userDocumentId]);

  const queryString = params.map(([key, value]) => `${key}=${value}`)
  return api.baseUrl + path + queryString.join('&');
}

export const setDocAsPending = async (documentType: string, data: setDocAsPendingBady): Promise<ScreeningResponse> => {
  return screeningApi.Post(`/screening/${documentType}/pend`, data)
}

export const setDocAsRejected = async (documentType: string, data: setTheReasonForRejection): Promise<ScreeningResponse> => {
  return screeningApi.Post(`/screening/${documentType}/reject`, data)
}

export const setDocAsValid = async (documentType: string): Promise<ScreeningResponse> => {
  return screeningApi.Post(`/screening/${documentType}/approve`,)
}

export const setFieldsOverride = async (data: FieldsOverridesType): Promise<ScreeningResponse> => {
  let serviceURL = '/screening/fields-overrides'
  return screeningApi.Post(serviceURL, data)
}

export const postponeAnalisys = async (): Promise<PostponeAnalysisResponse> => {
  let serviceURL = '/screening/postpone';
  return screeningApi.Post(serviceURL);
}

export const uploadDocumentScreening = async (data): Promise<ScreeningResponse> => {
  return screeningApi.Post("/documents/overwrite", data)
}

export const documentsToInsertScreening = async (data: DocumentsToInsertRequest): Promise<ScreeningResponse> => {
  return screeningApi.Post("/documents/documents-to-insert", data)
}

export const updateScreeningRules = async (data: IScreeningRules): Promise<ScreeningRulesResponse> => {
  return screeningApi.Put("/rules/update", data)
}

export const insertScreeningRules = async (data: IScreeningRules): Promise<ScreeningRulesResponse> => {
  return screeningApi.Post("/rules/insert", data)
}

export const insertImportScreeningRules = async (data: any): Promise<ScreeningRulesResponse> => {
  return screeningApi.Post("/rules/insert", data)
}

export const getEditionApplicationReportsSummaries = async (period: string):
  Promise<any> => {
  return misApi.Get(`/screening/documents/fields-overrides/${period}`)
}
export const getEditionApplicationReportsAnalytics = async (from: string, to: string): Promise<any> => {
  return screeningApi.Get(`/report/analytics/fields-overrides?${qs.stringify({ from: from, to: to })}`)
}

export const getAllSreeningRules = async (): Promise<Rules[]> => {
  return screeningApi.Get("/rules/all");
}

export const cloneDocument = async (data: cloneDocumentData): Promise<ScreeningResponse> => {
  return screeningApi.Post('documents/clone', data)
}

export const swapDocument = async (data: actionDocumentData): Promise<ScreeningResponse> => {
  return screeningApi.Post('documents/swap', data)
}

export const releaseScreening = async (): Promise<ScreeningResponse> => {
  return screeningApi.Get("/screening/release")
}

export const getFormalizationDocumentUrlByUserDocumentId = (userDocumentId: string): string => {
  return api.baseUrl + 'documents/' + userDocumentId + "?token=" + api.getAuth()
}

export const claimNextScreening = async (id = null): Promise<any> => {
  const query = id ? { id } : {}
  return screeningApi.Get('screening/pick', query)
}

export const getCurrentScreening = async (): Promise<ScreeningResponse> => {
  return screeningApi.Get('screening/current')
}

export const finishScreening = async (data): Promise<ScreeningResponse> => {
  return screeningApi.Post("/screening/finish", data)
}

export const getScreeningAnalystics = async (filter, limit, skip): Promise<AnalyticReportResponse> => {
  let serviceUrl = `/report/analytics?limit=${limit}`
  if (skip) {
    serviceUrl += `&skip=${skip}`
  }
  serviceUrl += screeningApi._buildQueryString(filter)
  return screeningApi.Get(serviceUrl);
}

export const getScreeningSummary = async (period): Promise<any> => {
  let serviceURL = "screening/" + period;
  return misApi.Get(serviceURL);
}

export const getScreeningTMA = async (period): Promise<any> => {
  let serviceURL = "screener/" + period;
  return misApi.Get(serviceURL);
}

export const getPendencyDocuments = async (period): Promise<any> => {
  let serviceURL = "screening/documents/pendency/" + period;
  return misApi.Get(serviceURL);
}

export const getReportCausesOfPendency = async (filter, limit, skip): Promise<any> => {
  let serviceUrl = `/report/analytics/documents?limit=${limit}`
  if (skip) {
    serviceUrl += `&skip=${skip}`
  }
  serviceUrl += screeningApi._buildQueryString(filter)
  return screeningApi.Get(serviceUrl);
}

export const getScreeningByPersonalNumberOrApplicationid = async (query: any = {}) => {
  return screeningApi.Get('/screenings', query)
}

export const getOverwriteDocuments = async (period: string): Promise<any> => {
  let serviceURL = '/screening/documents/overwrite/' + period;
  return misApi.Get(serviceURL);
}

export const getScreeningDocumentsOverwriteAnalystics = async (filter, limit, skip): Promise<OverwriteDocumentReportAnalyticsResponse> => {
  let serviceUrl = `/report/analytics/document/overwrite?limit=${limit}`
  if (skip) {
    serviceUrl += `&skip=${skip}`
  }
  serviceUrl += screeningApi._buildQueryString(filter)
  return screeningApi.Get(serviceUrl);
}

export const getPrioritizedBidsReport = async (period): Promise<PrioritizedBidsReportResponse> => {
  let serviceURL = `/report/analytics/priorizated` + period
  return screeningApi.Get(serviceURL)
}

export const getPostponedScreening = async (): Promise<PostponedScreeningResponse> => {
  return screeningApi.Get("/postponed")
}





