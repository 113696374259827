import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { routes, ScreeningTitle } from './screening-index';
import { InternalPendencyContainer } from '../../../Containers/Screening/InternalPendency'
import { claimNextScreening, getPostponedScreening } from '../../../services/api/screening';

export function InternalPendency(props) {
  const history = useHistory()
  const [data, setData] = useState(null)
  const [showTost, setShowTost] = useState(false)
  const [toastType, setToastType] = useState('warning')
  const [toastMessage, setToastMessage] = useState('')
  const { ns } = props
  const visibleColumns = [
    "applicationId",
    "branch",
    "date",
    "screenerName",
    "supplierName",
    'id'
  ]

  useEffect(() => {
    handleUpdateList()
  }, [])

  async function handleUpdateList() {
    try {
      const resultList: any = await getPostponedScreening()
      if (resultList?.length > 0) {
        setData(resultList)
      } else {
        setToastMessage('Nenhuma análise encontrada na fila interna.')
        setToastType('warning')
        setShowTost(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleGoToScreening = async screeningId => {
    try {
      const { status } = await claimNextScreening(screeningId)
      ns.setShared("SCREENING.screening_response")
      if (status !== 204) {
        history.push("/screening/initial-analysis")
      } else {
        setToastMessage('Hm, parece que não temos novos clientes na fila. Pausa para um café :D')
        setToastType('warning')
        setShowTost(true)
      }
    } catch (error) {
      let err: $TsFixMe = error
      console.error(err)
    }

  }

  const handleTostClose = () => setShowTost(false)


  return (
    <div style={{ padding: "2rem" }}>
      <ScreeningTitle step={routes.INTERNAL_PENDENCY} />
      <InternalPendencyContainer
        ns={ns}
        data={data}
        handleUpdateList={handleUpdateList}
        handleTostClose={handleTostClose}
        handleGoToScreening={handleGoToScreening}
        visibleColumns={visibleColumns}
        showTost={showTost}
        toastType={toastType}
        toastMessage={toastMessage}
      />
    </div>
  )
}