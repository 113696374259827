import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { StatsDetailHeader } from '../../../statistics/stats-components'
import { buildPeriodFilters } from '../../../components/table-standard';
import { setTTL } from '../../../business/misc-functions';
import { getProductChanges } from '../../../services/api/stats';
import { FzTable } from '../../fz/grid/table';
import { FzCard } from '../../fz/layout/FzCard'
import { FzNavSelect } from '../../fz/nav';
import { FzSplitLine } from '../../fz/layout';


interface ProductChangesProps {
  ns: any;
  metas: {};
  toggleBlocking: (
    callback?: any
  ) => void;
}

export function ProductChanges({ ns, toggleBlocking, metas }: ProductChangesProps) {

  const [requestState, setRequestState] = useState("idle")
  const [urlChecked, setUrlChecked] = useState(false)
  const [filters, setFilters] = useState({ period: "day" })

  useEffect(() => {
    if (urlChecked) {
      setUrlQueryString()
    }
  }, [filters])

  useEffect(() => {
    getQueryStringFromUrl()
  }, [])

  async function getStatistics() {
    try {
      setRequestState("requesting")
      toggleBlocking();
      const productChangesResults = await getProductChanges(getFilters());
      ns.set("qualified", productChangesResults, setTTL(5))
      ns.set("filter_periods", buildPeriodFilters(productChangesResults.statistics), null)
      toggleBlocking();
      setRequestState("idle");
    } catch (err) {
      let error: $TsFixMe = err
      setRequestState("idle")
      console.warn("API ERROR", error)
      toggleBlocking()
    }
  }

  function getFilters() {
    const query = filters.period + qs.stringify(filters, { addQueryPrefix: true })
    return query
  }

  function setUrlQueryString() {
    const query = qs.stringify(filters, { addQueryPrefix: true })
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
    history.pushState({ path: newurl }, '', newurl);
    getStatistics()
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params: any = Object.fromEntries(urlSearchParams.entries());
    if (params.period) {
      setFilters(params)
    } else {
      setUrlQueryString()
    }
    setUrlChecked(true)
  }

  let data = ns.get("qualified", {});
  if (!data && requestState === "requesting") {
    return null
  }

  let periodFilter = ns.get("filter_periods")

  return (
    <FzCard>
      <FzCard.Header>
        <FzCard.Title>
          <FzSplitLine>
            <FzSplitLine.Left></FzSplitLine.Left>
            <FzSplitLine.Right>
              <FzNavSelect
                value={filters.period}
                options={[
                  { "code": "day", "description": "Daily" },
                  { "code": "week", "description": "Weekly" },
                  { "code": "month", "description": "Monthly" },
                ]}
                onChange={(value) => {
                  setFilters({ ...filters, period: value })
                }}
              />
            </FzSplitLine.Right>
          </FzSplitLine>
          <div className="mt-3">
            <StatsDetailHeader
              title={'Product Changes'}
              periods={undefined}
              currPeriod={undefined}
              onChange={undefined}
              stats={data}
            />
          </div>
        </FzCard.Title>
      </FzCard.Header >
      <FzCard.Body>
        <FzTable
          data={data.statistics}
          ns={ns}
          metas={metas}
          visibleColumns={["key", "period", "oldProduct", "quantity", "newCP", "newRefin", "newFin", "newHE"]}
        />
      </FzCard.Body>
    </FzCard>
  )
}
