import React, { useState, useEffect } from 'react';
import qs from 'qs';
import clone from "lodash/cloneDeep"
import { pendencyDocumentsMeta } from '../../../../biz/metadatas/stats/screening/pendency-documents';
import { FzCard, FzSplitLine } from '../../../fz/layout/index';
import { FzNavSelect } from '../../../fz/nav';
import { StatsDetailHeader } from '../../../../statistics/stats-components';
import { FzTable } from '../../../fz/grid/table';
import { setTTL } from '../../../../business/misc-functions';
import { periodColumn } from '../../../../components/table-standard';
import { getDomain } from "../../../../scripts/utils/cache-helpers";
import { getPendencyDocuments } from '../../../../services/api/screening';

interface filtersType {
  period: string,
  interval: any,
}

export function PendencyDocuments(props) {
  const [requestState, setRequestState] = useState("idle")
  const [urlChecked, setUrlChecked] = useState(false)
  const filtersObj: filtersType = {
    period: "day",
    interval: null
  }
  const [filters, setFilters] = useState(filtersObj)
  const { ns } = props;
  let tableMeta = clone(pendencyDocumentsMeta)

  useEffect(() => {
    if (urlChecked) {
      setUrlQueryString()
    }
  }, [filters])

  useEffect(() => {
    getReport()
  }, [filters.period])

  useEffect(() => {
    getQueryStringFromUrl()
  }, [])


  function getFilters() {
    const query = filters.period + qs.stringify(filters, { addQueryPrefix: true })
    return query
  }

  function setUrlQueryString() {
    const query = qs.stringify(filters, { addQueryPrefix: true })
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
    history.pushState({ path: newurl }, '', newurl);
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params: any = Object.fromEntries(urlSearchParams.entries());
    if (params.period || params.interval) {
      setFilters(params)
    } else {
      setUrlQueryString()
    }
    setUrlChecked(true)
  }

  async function getReport() {
    const { ns } = props;
    setRequestState("requesting")
    const DocResults = await getPendencyDocuments(getFilters());
    ns.set("pendencyDocuments", DocResults.statistics, setTTL(5))
    ns.set("pendencyDocumentsResponse", DocResults.statistics)
    await ns.saveChange("filter_periods", buildPeriodFilters(DocResults.statistics), null)
    setRequestState("idle")
  }

  function getDocType(list) {
    return Array.from(new Set(list.map((it) => it.documentType)))
  }

  function getSupplierList(list) {
    return Array.from(new Set(list.map((it) => it.supplier)))
  }

  function buildTableMeta(rawData) {
    const suppliersList = getSupplierList(rawData).sort()
    suppliersList.forEach((v: any) => {
      let template = clone(tableMeta.supplier)

      
      tableMeta[v] = template
      tableMeta[v].path = v
      tableMeta[v].label = v
      tableMeta[v].tableProps.formatExtraData = v + 'TotalAnalyzed'
      tableMeta[v].tableProps.spanColumns.columns[0].label = v
      tableMeta[v].tableProps.spanColumns.columns[1].path = v + 'TotalAnalyzed'
    })
    delete tableMeta.supplier
  }

  function processTableData(rawData) {
    buildTableMeta(rawData)
    let docList: any = getDocType(rawData).sort()
    let currentDoc = null
    let total = 0
    let totalAnalyzedOfDoc = 0
    const tableData = docList.map((doc) => {
      if (currentDoc !== doc) {
        total = 0
        totalAnalyzedOfDoc = 0
      }
      return rawData.reduce((acc, current) => {
        if (current.documentType === doc) {
          return {
            ...acc,
            key: current.key,
            documentType: getDomain(ns, 'formalization-documents', doc),
            [current.supplier]: current.pending,
            [current.supplier + 'TotalAnalyzed']: current.totalAnalyzed,
            total: acc.total + current.pending,
            totalAnalyzedOfDoc: acc.totalAnalyzedOfDoc + current.totalAnalyzed
          }
        }
        return { ...acc }
      }, {
        total: total,
        totalAnalyzedOfDoc: totalAnalyzedOfDoc
      })
    })

    return tableData
  }

  function handleChangePeriod(value) {
    setFilters({ ...filters, interval: value })
  }

  function buildPeriodFilters(statisticsData) {
    let periods = {};
    const sortedData = statisticsData.sort()
    if (sortedData) {
      for (var i = 0; i < sortedData.length; ++i) {
        let p = sortedData[i].period;
        if (p && !periods[p]) {
          periods[p] = periodColumn(p);
        }
      }
    }
    return periods;
  };

  let filterValues = ns.getChanged("filter_periods") || [];
  let dataResult = ns.get('pendencyDocuments', [])

  let selectedPeriod = filters.interval || Object.keys(filterValues || { "": "" })[0];
  if (selectedPeriod) {
    dataResult = dataResult.filter((it) => {
      return it['period'] === selectedPeriod
    })
  }
  if (dataResult.length > 0) {
    dataResult = processTableData(dataResult)
  }

  let paths = Object.keys(tableMeta).filter(it => it !== "key" && it !== "supplier")

  return (
    <FzCard>
      <FzCard.Body>
        <FzCard>
          <FzCard.Heading fzStyle={{}}>
            <FzCard.Title>
              <FzSplitLine>
                <FzSplitLine.Left />
                <FzSplitLine.Right>
                  <FzNavSelect
                    fzStyle={{ marginRight: 0 }}
                    value={filters.period}
                    options={[
                      { "code": "day", "description": "Daily" },
                      { "code": "week", "description": "Weekly" },
                      { "code": "month", "description": "Monthly" },
                    ]}
                    onChange={(value) => {
                      setFilters({ ...filters, period: value, interval: null })
                    }}
                  />
                </FzSplitLine.Right>
              </FzSplitLine>
              <div className="mt-3">
                <StatsDetailHeader
                  title="Pendências de documentos"
                  stats={ns.get("pendencyDocumentsResponse")}
                  periods={filterValues}
                  currPeriod={selectedPeriod}
                  onChange={handleChangePeriod}
                />
              </div>
            </FzCard.Title>
          </FzCard.Heading>
        </FzCard>
        <div className={'mt-3'}>
          <FzTable
            data={dataResult}
            metas={tableMeta}
            visibleColumns={["key", ...paths]}
            context={'pendencyDocuments'}
            pagination
            ns={ns}
          />
        </div>
      </FzCard.Body>
    </FzCard >
  )
}