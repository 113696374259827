import api from './client-api';

export type ParameterResponse = {
  key: string,
  type: string,
  value: string,
}

export const getParameterNames = async () :Promise<string[]> => {
  return api.Get('/parameters/')
}

export const getParameterKeys = async (domainName: string) :Promise<string[]> => {
  return api.Get('/parameters/' + domainName)
}

export const deleteParameterKey = async (domainName: string, key: string) :Promise<void> => {
  return api.Delete('/parameters/' + domainName + '/' + key)
}

export const getParameterValue = async (domainName, key): Promise<ParameterResponse> => {
  return api.Get('/parameters/' + domainName + '/' + key)
}

export const saveParameterValue = async (domain: string, key: string, data: any): Promise<ParameterResponse> => {
  let serviceURL = '/parameters/' + domain + '/' + key;
  return api.Put(serviceURL, data);
}
