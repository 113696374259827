import React, { useState, useEffect } from 'react';
import { FzCard, FzRow, FzCol, FzForm } from '../../../fz/layout/index'
import FieldConnected from '../../../components/form/field-connected';
import { buildPeriodFilters, buildProductFilters } from '../../../../components/table-standard';
import { getStatisticsFunnelOperationalOverview, getStatisticsFunnelOperationalOverviewDS } from '../../../../services/api/stats';
import cloneDeep from "lodash/cloneDeep";
import { FunnelOpeOverview } from './tables/operational-overview-table';
import { buildDataForFunnel, buildDataForLine } from '../../../fz/formatter/charts'
import { FzFunnel } from '../../../fz/charts/funnel'
import { FzLine } from '../../../fz/charts/line'
import { panelControl } from '../../../../biz/metadatas/panel-control-funnel'
import { FzCheckbox, FzButton } from '../../../fz/form';
import qs from 'qs';

export function OverviewPanel (props) {

  const [requestState, setRequestState] = useState('idle')
  const [urlChecked, setUrlChecked] = useState(false)
  const [filters, setFilters] = useState({
    period: null,
    product: null,
    checked: null,
    agregado: null,
    view: null,
  })
  const [periodsList, setPeriodsList] = useState({})
  const [productsList, setProductsList] = useState({})
  const [showDataStudio, setShowDataStudio] = useState(false)
  const [dataStudioValue, setDataStudioValue] = useState(null)
  const { ns } = props

  useEffect(() => {
    if (urlChecked) {
      setUrlQueryString()
    }
  }, [ns])

  useEffect(() => {
    const product = ns.getChanged('product')
    if(product){
      handleChangeSelect()
    }
  }, [productsList, periodsList])

  useEffect(() => {
    ns.startEditing()
    getQueryStringFromUrl()
    getStatisticsFunnelOperationalOverviewDS().then((result) => {
      setDataStudioValue(result.value)
    }).catch((err) => {
      console.warn("Not able to get FUNNEL-OVERVIEW Data Studio iframe", err)
    })
  }, []) 

  
  function setUrlQueryString() {
    let changes = ns.getChanged()
    if(changes){
      let filtersParams = {
        agregado: changes.agregado,
        view: changes.view,
        pivot: changes.pivot,
        period: changes.period,
        product: changes.product,
        typeChart: changes.typeChart
      }
      const query = qs.stringify(filtersParams, { addQueryPrefix: true })
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
      history.pushState({ path: newurl }, '', newurl);
    }
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params: any = Object.fromEntries(urlSearchParams.entries());
    const listOfQueryParams = Object.keys(params)
    if (listOfQueryParams.length > 0) {
      for (let i = 0; i < listOfQueryParams.length; i++) {
        ns.saveChange(listOfQueryParams[i], params[listOfQueryParams[i]])
      }
    }
    setUrlChecked(true)
  }
  

  function getStatistics(period) {
    setRequestState("requesting")
    setFilters({...filters, period: period})
    props.toggleBlocking(() => {
      getStatisticsFunnelOperationalOverview(period)
        .then((data) => {
          ns.set("funnelOpeOverview", data)
          ns.saveChange("filter.periods", buildPeriodFilters(data.statistics))
          ns.saveChange("filter.product", buildProductFilters(data.statistics))
          setPeriodsList(buildPeriodFilters(data.statistics))
          setProductsList(buildProductFilters(data.statistics))
          setRequestState("idle")
          props.toggleBlocking()
        })
        .catch((err) => {
          console.warn("API ERROR", err)
          props.toggleBlocking()
        })
    })
  }

  function handleChange(index, e) {
    if (e && e.target.value) {
      const newState = { ...ns.getChanged("funnels") }
      newState["funnel" + index] = e.target.value
      ns.saveChange("funnels", newState)
    }
  }

  function buildFunnel(funnelCharts, viewOptions, selectedView, ns) {
    const maxItems = 4
    if (ns.getChanged("dataFunnelChart")) {

      const keys = Object.keys(ns.getChanged("dataFunnelChart"));

      funnelCharts = keys.filter((itens, index) => index < maxItems).map((it, index) => {
        let select: any = null


        if (viewOptions && ns.getChanged('funnels')) {

          const listKeys = Object.keys(viewOptions).map((opt, index) => {
            return <option key={index} value={opt}>{viewOptions[opt]}</option>
          })

          select = <FzForm.Control
            as="select"
            name="periodList"
            placeholder="Select the period to display..."
            value={ns.getChanged("funnels")["funnel" + index]}
            onChange={(e) => handleChange(index, e)}
          >
            <option value="">Select the period to display...</option>
            {listKeys}
          </FzForm.Control >
        }

        return (
          <div>
            {selectedView === "period" ? (
              select) : (null)}
            {ns.getChanged('funnels') ? 
              <FzFunnel options={ns.getChanged("dataFunnelChart")[ns.getChanged("funnels")["funnel" + index]]} />
            : null}

          </div>
        );
      });

    }
    return funnelCharts;

  }

  function handleChangeSelect() {
    let filterValues2 = ns.getChanged("pivot") === "product" ? ns.getChanged("filter.product") : ns.getChanged("filter.periods");
    if (filterValues2) {
      const keysOfSelectedView = Object.keys(filterValues2);
      const funnelsKeys = keysOfSelectedView.reduce((acumulador, current, index) => {
        return { ...acumulador, ["funnel" + index]: current };
      }, {});

      ns.saveChange("funnels", funnelsKeys);
    }
  }

  function handleChangeCheckbox(value) {
    setFilters({...filters, checked: value})
  }

  function handleShowDataStudio() {
    setShowDataStudio(!showDataStudio)
  }

  function refreshData() {

    const rawData = ns.get("funnelOpeOverview") || {}
    const path = ns.getChanged("pivot")

    buildDataForFunnel(rawData.statistics, ns, ns.getChanged("pivot"), filters.checked, ns.getChanged(path))
    buildDataForLine(rawData.statistics, ns.getChanged("filter.periods"), ns, ns.getChanged("period"), ns.getChanged("agregado"))
  }

  let funnelCharts = []

  let data = ns.getChanged() || {};
  const rawData = ns.get("funnelOpeOverview") || {}
  const path = ns.getChanged("pivot")

  let filterValues = ns.getChanged("pivot") === "product" ? ns.getChanged("filter.periods") : ns.getChanged("filter.product")


  let filterMeta
  if (ns.getChanged("pivot") !== undefined && ns.get("funnelOpeOverview") !== undefined) {

    filterMeta = cloneDeep(panelControl[path]);
    if (filterValues) {
      filterMeta.inputProps.options = (Object.keys(filterValues) || []).map(it => ({
        code: it,
        description: filterValues[it]
      }));
    }
  }


  if (ns.getChanged("dataFunnelChart") === undefined) {
    if(rawData.statistics && ns.getChanged('filter.product')) {
      buildDataForFunnel(rawData.statistics, ns, ns.getChanged("pivot"), filters.checked, ns.getChanged(path))
    }
  }

  if (
    ns.getChanged("dataLineChart") === undefined &&
    ns.getChanged("filter.periods") !== undefined &&
    ns.getChanged("period") !== undefined) {
    buildDataForLine(rawData.statistics, ns.getChanged("filter.periods"), ns, ns.getChanged("period"), ns.getChanged("agregado"))
  }

  let viewOptions = null
  if (ns.getChanged("pivot") === "period") {
    viewOptions = ns.getChanged("filter.periods")
  }


  funnelCharts = buildFunnel(funnelCharts, viewOptions, ns.getChanged("pivot"), ns);



  if (ns.getChanged("agregado") !== undefined && filters.period !== ns.getChanged("agregado") && requestState === 'idle') {
    getStatistics(ns.getChanged("agregado"))
  }

  return (
    <>
      {!showDataStudio && dataStudioValue ? <button className="btn btn-primary mt-1 mb-1" onClick={handleShowDataStudio}>Data Studio View</button> : <button className="btn btn-primary mt-1 mb-1" onClick={handleShowDataStudio}>COF View</button>}
      {showDataStudio && dataStudioValue ? <div dangerouslySetInnerHTML={{ __html: dataStudioValue }} /> :
      <FzCard>
        <FzCard>
          <FzCard.Heading fzStyle={{}}>
            <div></div>
          </FzCard.Heading>
          <FzCard.Body>
            <FzRow>
              <div className={"fz-panel"} >
                <FzCol span={2}>
                  <div className={"fz-panel-border-padding"}>
                    <FieldConnected data={data} meta={panelControl["agregado"]} ns={ns} />
                  </div>
                </FzCol>
                <FzCol span={2}>
                  <div className={"fz-panel-border-padding"}>
                    <FieldConnected data={data} meta={panelControl["view"]} ns={ns} />
                  </div>
                </FzCol>
                {ns.getChanged("view") === "charts" ? (
                  <FzCol span={2}>
                    <div className={"fz-panel-border-padding"}>
                      <FieldConnected data={data} meta={panelControl["typeChart"]} ns={ns} />
                    </div>
                  </FzCol>) :
                  null
                }
                <FzCol span={2}>
                  <div className={"fz-panel-border-padding"}>
                    <FieldConnected data={data} meta={panelControl["pivot"]} ns={ns} />
                  </div>
                </FzCol>
                <FzCol span={2}>
                  <div className={"fz-panel-border-padding"} onChange={handleChangeSelect}>
                    {ns.getChanged("pivot") !== undefined && ns.get("funnelOpeOverview") !== undefined ?
                      (<FieldConnected ns={ns} meta={filterMeta} data={data} />) : null}
                  </div>
                </FzCol>
                <FzCol span={2}>
                  <div style={{ display: "inline-grid"}}>
                    <FzCheckbox checked={filters.checked} inline={true} onChange={handleChangeCheckbox}>Visualização porcentagem</FzCheckbox>
                    <FzButton onClick={refreshData}>{"Atualizar "}</FzButton>
                  </div>
                </FzCol>
              </div>
            </FzRow>
          </FzCard.Body>
        </FzCard>
      {ns.getChanged("view") !== undefined ? (
        <FzCard>
          {ns.getChanged("view") === "table" ? (<FzCard.Body>
            <FunnelOpeOverview
              data={rawData}
              filterColumnName={ns.getChanged("pivot") === "product" ? "period" : "product"}
              filterValue={ns.getChanged(path)}
              pivotColumnName={ns.getChanged("pivot") || "product"}
            />
          </FzCard.Body>) : null}
          {ns.getChanged("view") === "charts" && ns.getChanged("typeChart") === "funnel" ? (
            <FzCard.Body>
              <FzRow>
                {funnelCharts.map((element, index) => {
                  return (<FzCol key={index} span={3}>
                    {element}
                  </FzCol>)
                })}
              </FzRow>
            </FzCard.Body>) : null}
          {ns.getChanged("view") === "charts" && ns.getChanged("typeChart") === "line" ? (
            <FzRow>
              <FzLine options={ns.getChanged("dataLineChart")} />
            </FzRow>
          ) : null}
        </FzCard>) : null}
      </FzCard >}
    </>
  )
}