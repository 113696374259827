import { useEffect, useState } from 'react'
import { jwtDecode } from "jwt-decode";
import { loginWithGAuth, clearAuth, setAuth } from '../../api/auth';
import { FzToast } from '../fz/form/notification/toast';
import { useWindowSize } from '../../utils/useWindowSize';

export default function GAuth() {

  useEffect(() => {
    initGauth();
    let timer;

    window.handleCredentialResponse = async (credentialResponse) => {

      const userData = await jwtDecode(credentialResponse.credential);

      try {
        const { gtoken, token, user } = await loginWithGAuth(userData.email, credentialResponse.credential);
        await setAuth(token, user, gtoken);
        saveSession(userData, credentialResponse.credential);
        window.location.href = '/';

      } catch (error) {
        setToast({
          show: true,
          message: 'Erro ao fazer login, tente novamente.',
          type: 'danger',
          header: 'Erro',
        });
        console.log(error)
        timer = setTimeout(() => {
          clearAuth();
        }, 2000);
      }
    }

    return () => {
      clearTimeout(timer);
    }
  }, []);

  const screen = useWindowSize();

  const [toast, setToast] = useState({
    show: false,
    message: '',
    type: '',
    header: '',
  });

  function initGauth() {
    const gaScript = document.getElementById('gauth-script');

    if (!gaScript) {
      let gsiClient = document.createElement('script');
      gsiClient.src = 'https://accounts.google.com/gsi/client';
      gsiClient.async = true;
      gsiClient.defer = true;
      gsiClient.id = 'gauth-script';
      gsiClient.type = 'text/javascript';

      document.head.appendChild(gsiClient);
      gsiClient.onload = () => {
        initGauth();
      }
    }
  }

  function saveSession(userData, credential) {
    sessionStorage.setItem('fz-ga-name', userData.name);
    sessionStorage.setItem('fz-ga-email', userData.email);
    sessionStorage.setItem('fz-ga-token', credential);
  }

  return (
    <>
      <FzToast
        show={toast.show}
        bodyMessageTost={toast.message}
        fzStyle={toast.type}
        headerMessageTost={toast.header}
        delay={5000}
        autoHide={true}
        close={() => setToast({ show: false, message: '', header: '', type: '' })}
      />
      <div
        id="g_id_onload"
        data-client_id="1031345602560-pn5jvejc3dc4jb5uemk8iu87mp7uvqr1.apps.googleusercontent.com"
        data-callback="handleCredentialResponse"
      />
      <div
        className="g_id_signin"
        data-type="standard"
        data-theme="filled_blue"
        data-size={screen.width >= 425 ? "large" : "medium"}
        data-width={screen.width >= 425 ? "400" : ""}
      />
    </>
  )
}