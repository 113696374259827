import React, { ReactNode, useState } from 'react'
import { useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Queue } from './queue'
import { InitialAnalysis } from './initial-analysis'
import { FinalAnalysis } from './final-analysis'
import { InternalPendency } from './internal-pendency'
import api from '../../../api/client-api'
import { FzToast } from '../../fz/form/notification/toast';
import { IndividualAnalysis } from './IndividualAnalize';
import { getCurrentScreening } from '../../../services/api/screening';
import { ModalDocumentsPendency } from '../../../Containers/Screening/modal/ModalDocumentsPendency';
import { FzButton } from '../../fz/form';
import { getScreeningRulesTranslation } from '../../../scripts/utils/cache-helpers'
import { filterDomainDescription } from '../../../utils/filterDomainDescription';

const screeningRoot = "/screening/"

export const ScreeningDocumentStates = {
  NOT_INITIALIZED: 'not-initialized',
  REJECTED: 'rejected',
  VALIDATED: 'validated',
  PENDING: 'pending',
}

export const ScreeningDocumentStatesMeta = {
  [ScreeningDocumentStates.NOT_INITIALIZED]: { label: "não iniciado" },
  [ScreeningDocumentStates.REJECTED]: { label: "rejeitado" },
  [ScreeningDocumentStates.VALIDATED]: { label: "validado" },
  [ScreeningDocumentStates.PENDING]: { label: "pendenciado" },
}

export const routes = {
  CUSTOMER: "customer",
  QUEUE: "queue",
  INITIAL_ANALYSIS: "initial-analysis",
  INDIVIDUAL_ANALYSIS: "individual-analysis",
  INDIVIDUAL_ANALYSIS_SELFIE: "individual-analysis-selfie",
  CONTINUE_ANALYSIS: "continue-analysis",
  FINAL_ANALYSIS: "final-analysis",
  CONTINUE_FINAL_ANALYSIS: "continue-final-analysis",
  INTERNAL_PENDENCY: 'internal-pendency'
}

export const routesMeta = {
  [routes.QUEUE]: { title: "Etapa da Fila", menuItemName: "Fila", stepNumber: 1 },
  [routes.INITIAL_ANALYSIS]: { title: "Análise preliminar", menuItemName: "Análise inicial", stepNumber: 2 },
  [routes.INDIVIDUAL_ANALYSIS]: { title: "Análise de documento individual", menuItemName: "Análise individual", stepNumber: 3, hasSubSteps: true },
  [routes.FINAL_ANALYSIS]: { title: "Análise final", menuItemName: "Análise final", stepNumber: 5 },
  [routes.CUSTOMER]: { title: "Análises em aberto", menuItemName: "Buscar clientes", stepNumber: 6 },
  [routes.INTERNAL_PENDENCY]: { title: "Pendência interna", menuItemName: "Pendência interna"}
}

const fetchData = async (ns) => {
  let formalizationDocs = await api.domains.getDomainValues("formalization-documents")

  formalizationDocs = filterDomainDescription(formalizationDocs)

  ns.setShared("SCREENING.domains.formalization_documents", formalizationDocs)
  await getScreeningRulesTranslation(ns)
}

const ScreeningDetails = (props) => {
  const { screeningResponse, ns } = props
  const [docModal, setDocModal] = useState(false)

  function openDocModal() {
    setDocModal(true)
  }

  function closeDocModal() {
    setDocModal(false)
  }

  return (
    <>
      <div className={props.className} style={props.style}>
        <div>Parceiro: {screeningResponse.supplierName}</div>
        {screeningResponse.formalizationCreated && <div>Data criação da formalização: {new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short' }).format(new Date(screeningResponse.formalizationCreated))} </div>}
        <div>Passagem: {screeningResponse.screenedCount} </div>
        <div>Branch Code: {screeningResponse.branchCode} </div>
        <div>ApplicationId: {screeningResponse.applicationId} </div>
        {
        screeningResponse.validationStepsHistory &&
        <FzButton onClick={openDocModal}>
          Histórico
        </FzButton>
        }
      </div>
      <ModalDocumentsPendency 
        modalOpen={docModal}
        closeModal={closeDocModal}
        validationStepsHistory={screeningResponse.validationStepsHistory}
        ns={ns}
      />
    </>
  )
}

const ScreeningTitle = (props) => {
  const { docName, step, totalNumberOfDocs, currentDocIndex, docSubType } = props

  let titleText = ""
  if (!!docName) {
    titleText = "Triagem - " + docName + (docSubType ? ` - ${docSubType.description}` : '') + " - " + routesMeta[step].title + " (" + currentDocIndex + " / " + totalNumberOfDocs + ")"
  } else {
    titleText = "Triagem - " + routesMeta[step].title
  }
  return <h3 className="mb-4">{titleText}</h3>

}

async function refreshScreening(ns, history, setHeaderMessageTost, setBodyMessageTost, seColorTost, setShow) {
  try {
    const ScreeningResponse = await getCurrentScreening()
    ns.setShared("SCREENING.screening_response", ScreeningResponse)
  }catch (err) {
    let error: $TsFixMe = err
    if (error.response.status === 400) {
      setHeaderMessageTost('Screening error')
      setBodyMessageTost('Ops, essa proposta foi excluída ou cancelada. Vamos encontrar uma proposta ativa para você')
      seColorTost('danger')
      setShow(true)
      setTimeout(function () {
        history.push("/screening/queue")
      }, 5000);
    } else {
      setHeaderMessageTost('Screening error')
      setBodyMessageTost(error?.response?.message)
      seColorTost('danger')
      setShow(true)
    }
  }
}

const Screening = (props) => {
  const [show, setShow] = useState(false)
  const [headerMessageTost, setHeaderMessageTost] = useState('')
  const [bodyMessageTost, setBodyMessageTost] = useState('')
  const [colorTost, seColorTost] = useState('')

  const history = useHistory();
  const { match, ns } = props;
  let stepComponent: ReactNode = null;

  useEffect(() => {
    if (history.location.pathname !== "/screening/queue") {
      refreshScreening(ns, history, setHeaderMessageTost, setBodyMessageTost, seColorTost, setShow)
    }
    fetchData(ns)
  }, [])

  function handleClose() {
    setShow(false)
  }

  if (!ns.getShared("SCREENING.domains.formalization_documents", null)) {
    return <div>Loading formalization documents</div>
  }

  switch (match.params.step) {
    case routes.QUEUE:
      stepComponent = <Queue ns={ns} />;
      break;
    case routes.INITIAL_ANALYSIS:
      stepComponent = <InitialAnalysis ns={ns} />;
      break;
    case routes.INDIVIDUAL_ANALYSIS:
      stepComponent = <IndividualAnalysis ns={ns} documentType={match.params.documentType} />;
      break;
    case routes.CONTINUE_ANALYSIS:
      stepComponent = <IndividualAnalysis ns={ns} continuing={true} documentType={match.params.documentType} />;
      break
    case routes.FINAL_ANALYSIS:
      stepComponent = <FinalAnalysis ns={ns} />;
      break;
    case routes.CONTINUE_FINAL_ANALYSIS:
      stepComponent = <FinalAnalysis ns={ns} continuing={true}/>;
      break;
    default: stepComponent = <div>Rota não corresponde a nenhuma etapa da triagem</div>
  }

  return <div style={{ padding: "2rem" }}>
    {stepComponent}
    <FzToast
      fzStyle={colorTost}
      close={handleClose}
      show={show}
      delay={5000}
      headerMessageTost={headerMessageTost}
      bodyMessageTost={bodyMessageTost}
      autoHide={false}
    />
  </div>
}

export {
  Screening,
  ScreeningTitle,
  ScreeningDetails,
  screeningRoot
}