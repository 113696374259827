import { get, remove, set } from 'js-cookie'

function getGAuthToken() {
  const gaToken = localStorage.getItem("fz-ga-token");
  
  if (gaToken) {
    return localStorage.getItem(gaToken);
  }

  return ""
}

function getCookie(name) {
  if (!name) return get() ?? null;

  return get(name) ?? null;
}

function setCookie(name, val, expires) {
  set(name, val, { expires })
}

function removeCookie(name) {
  remove(name)
}

function clearAuth() {
  localStorage.removeItem("currentUser");
  localStorage.removeItem("fz-ga-token")
  localStorage.removeItem("fz-cof-token")

  Object.keys(getCookie()).forEach((cookie) => removeCookie(cookie))
}

function getLogoutURL(location) {
  const loginUrl = '/login';
  if (location?.pathname === loginUrl || location?.pathname === '/logout') {
    return loginUrl + location.search;
  }
  if (location?.search?.indexOf('?nextPathname=') > -1) {
    return loginUrl + location.search;
  }
  return `/login?nextPathname=${location?.pathname || '/'}${location?.search || ''}`;
}

export { getCookie, setCookie, removeCookie, clearAuth, getGAuthToken, getLogoutURL };