import React from 'react';
import { FzCard } from '../fz/layout/index';
import { FzTable } from '../fz/grid/table'
import { getTemplates } from '../../services/api/admin';
import { Meta } from "../../types/metas"

export class EmailTemplateGo extends React.Component<any, any>  {
  constructor(props) {
    super(props)

    this.state = { emailTemplates: null, open: true };
    this.getStatistics = this.getStatistics.bind(this)
  }


  componentDidMount() {
    this.getStatistics()
  }

  getStatistics() {
    const { ns } = this.props

    this.setState({ requested: "requesting", blockStatsConsole: true }, () => {

      getTemplates()
        .then((data) => {
          const templetesGO = Object.keys(data.emailTemplates)
            .map(it => ({ template: it, emailId: data.emailTemplates[it] }))
          ns.set("templetesGO", templetesGO)
          ns.set("postponeSeconds", data.postponeSeconds)

          this.setState({ requested: "idle" })
        })
        .catch((err) => {
          this.setState({ requested: "idle" }, () => {
            console.error(err)
          })
        })
    })
  }




  render() {

    const metas: Meta = {
      "template": {
        "path": "template",
        "dataType": "string",
        "label": "Templete",
        "tableProps": {
          "width": "10ch",
          "filter": { type: 'TextFilter', delay: 1000 },
          "dataSort": true,
          "dataAlign": "center",
          "isKey": true,
        },
        "isMandatory": () => false
      },
      "emailId": {
        "path": "emailId",
        "dataType": "string",
        "label": "SendGrid Id",
        "tableProps": {
          "width": "10ch",
          "filter": { type: 'TextFilter', delay: 1000 },
          "dataSort": true,
          "dataAlign": "center",
        },
        "isMandatory": () => false
      },
    }

    const { ns } = this.props

    let data = ns.get("templetesGO");
    let postponeSeconds = ns.get("postponeSeconds")

    let { requestState } = this.state;

    if (!data && requestState === "requesting") {
      return null
    }

    return (
      <>
        <FzCard >
          <FzCard.Heading  fzStyle={{}}>
            <FzCard.Title >Templates de Email</FzCard.Title>
          </FzCard.Heading>
          <FzCard.Body>
            <FzCard.Body>
              <FzCard>
                <FzCard.Heading  fzStyle={{}}>
                  <FzCard.Title>Segundos de atraso para envio dos email de fechamento: <b>{postponeSeconds}</b></FzCard.Title>
                </FzCard.Heading>
              </FzCard>
              <FzTable
                data={data}
                metas={metas}
                ns={this.props.ns}
              />
            </FzCard.Body>
          </FzCard.Body>
        </FzCard>
      </>
    )
  }
}


