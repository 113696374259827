import { useEffect, useState } from "react";
import { EditionApplicationReportsContainer } from "../../../../Containers/Screening/EditionApplicationReports";
import api from '../../../../api/client-api'
import { getEditionApplicationReportsSummaries, getEditionApplicationReportsAnalytics } from "../../../../services/api/screening";
import moment from "moment";
import { buildPeriodFilters } from "../../../../components/table-standard";
import { FzToast } from "../../../fz/form/notification/toast";

export function ReportEditionApplication({ ns, toggleBlocking }) {

  useEffect(() => {
    ns.saveChange("from", moment().subtract(10, "days").startOf("day").toISOString())
    ns.saveChange("to", moment().endOf("day").toISOString())
  }, [])

  const [typeView, setTypeView] = useState('')
  const [data, setData] = useState<any>({ result: [] })
  const [toastInfo, setToastInfo] = useState({
    status: false,
    headerMessage: "Error",
    bodyMessage: "Erro interno, tente novamente!",
    fzStyle: "danger"
  });

  function handlePage(pageType: string): void {
    setTypeView(pageType)
    setData({ result: [] })
  }


  async function getSummaraiesData(period: string) {

    try {
      const result = await getEditionApplicationReportsSummaries(period)
      const periodsFiltered = buildPeriodFilters(result.statistics)
      let editionApplicationResult = {
        result: result.statistics,
        periods: periodsFiltered,
        rawData: result
      }
      setData(editionApplicationResult)
    } catch (error) {
      console.error(error);
      handleToast(true)
    }
  }


  async function getAnalyticsData(from: string, to: string) {
    from = from.split("T")[0];
    to = to.split("T")[0];
    try {
      const result = await getEditionApplicationReportsAnalytics(from, to)
      if (result?.length > 0) {
        setData({ result: result });
        return;
      }
      handleToast(true, "Aviso", "Não temos screenings finalizados para está data!");
    } catch (error) {
      console.error(error);
      handleToast(true)
    }
  }

  function handleSearchReport(filter) {
    if (typeView === "summaries") {
      getSummaraiesData(filter.period)
    } else {
      getAnalyticsData(filter.from, filter.to)
    }
  }

  const handleToast = (
    status: boolean,
    headerMessage = "Error",
    bodyMessage = "Erro interno, tente novamente!",
    fzStyle = "danger"
  ) => {
    setToastInfo({
      ...toastInfo,
      status: status,
      headerMessage: headerMessage,
      bodyMessage: bodyMessage,
      fzStyle: fzStyle
    })
  }

  const handleToastClose = () => setToastInfo({ ...toastInfo, status: false })

  const { role = 'no-role' } = api.getCurrentUser()
  const filters = ns.getChanged()
  return (
    <div>
      <EditionApplicationReportsContainer
        ns={ns}
        handlePage={handlePage}
        typeView={typeView}
        handleSearchReport={handleSearchReport}
        data={data}
        role={role}
        filters={filters}
      />
      <FzToast
        fzStyle={toastInfo.fzStyle}
        close={handleToastClose}
        show={toastInfo.status}
        delay={5000}
        headerMessageTost={toastInfo.headerMessage}
        bodyMessageTost={toastInfo.bodyMessage}
        autoHide={true}
      />
    </div>
  )
}