import { hermesApi } from "../../../api/client-api";

export const getHermesUser = async (id: string): Promise<any> => {
  return hermesApi.Get(`user/${id}`);
}

export const getHermesUsers = async (): Promise<any> => {
  return hermesApi.Get('users');
}

export const createHermesUser = async (body): Promise<any> => {
  return hermesApi.Post('user', body)
}

export const updateHermesUser = async (id:string, body): Promise<any> => {
  return hermesApi.Put(`user/${id}`, body)
}

export const getHermesQueues = async (): Promise<any> => {
  return hermesApi.Get('queues')
}

export const getHermesQueueById = async (id: string): Promise<any> => {
  return hermesApi.Get(`queue/${id}`)
}

export const postHermesQueue = async (body): Promise<any> => {
  return hermesApi.Post('queue', body)
}

export const updateHermesQueue = async (id: string, body): Promise<any> => {
  return hermesApi.Put(`queue/${id}`, body)
}

export const getHermesPick = async (): Promise<any> => {
  return hermesApi.Get('/ticket/pick')
}

export const getHermesCurrent = async (): Promise<any> => {
  return hermesApi.Get('/ticket/current')
}

export const getTicketsAll = async (): Promise<any> => {
  return hermesApi.Get('/tickets')
}

export const getTicketById = async (id): Promise<any> => {
  return hermesApi.Get(`/ticket/${id}`)
}

export const handleAuctionQueues = async (queueId: string, parameter: string, query: boolean): Promise<any> => {
  const hasQuery = query ? "?clear=true" : "";
  return hermesApi.Put("/queue/" + queueId + "/" + parameter + hasQuery)
}

export const finalizeTicketAttendance = async (
  ticketId: string, 
  payload: any,
) => {
  return hermesApi.Post("/ticket/resolve/" + ticketId, payload)
}

export const getApplicationTickets = async (appId): Promise<any> => {
  return hermesApi.Get(`/tickets/application/${appId}`)
}

export const postInboundTicket = async (payload) => {
  return hermesApi.Post("/ticket/inbound", payload)
}

export const getTicketsReport = async (from, to, notInitialized): Promise<any> => {
  return hermesApi.Get(`/tickets/report?from=${from}&to=${to}&notInitialized=${notInitialized == true ? true : ''}`)
}