
import { useEffect, useState } from "react";
import { FieldValidationContainer } from "../../../../Containers/FieldValidationReport";
import { getFormValidationStats } from "../../../../services/api/stats";
import { FieldValidationReportProps } from "./types";

export function FieldValidationReport({
  ns,
  toggleBlocking
}: FieldValidationReportProps) {

  const [data, setData] = useState<{[key: string]: any}>([])

  async function getStatistics(period: string) {
    try {
      const result = await getFormValidationStats(period)
      ns.set("form-validation", result);
      setData(result)
    } catch (error) {
      console.warn("API ERROR", error);
      toggleBlocking()
    }
  }

  function handleSearchReport(period: string) {
    getStatistics(period)
  }

  return (
    <div>
      <FieldValidationContainer
        ns={ns}
        data={data}
        handleSearchReport={handleSearchReport}
      />
    </div>
  )
}