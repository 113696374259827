import get from 'lodash/get';
import { FzCard, FzSplitLine} from '../../../fz/layout/index';
import { FzTable } from '../../../fz/grid/table';
import { FzButton } from '../../../fz/form/button';
import { getHermesQueues, getHermesUsers, handleAuctionQueues } from '../../../../services/api/hermes';
import { QueueMeta } from '../../../../Containers/Hermes/queues/metadataQueues';
import { useState, useEffect } from 'react';
import { FzToast } from '../../../fz/form/notification/toast';
import { getFieldsQueryBuilder, translationsConfig } from '../../../../scripts/utils/jsonLogic-helpers';
import { getDomains } from '../../../../api/domains';
import { segmentationsHermesMeta } from '../../../../Containers/Hermes/queues/metaQueryBuilder';
import { MuiConfig } from '@react-awesome-query-builder/mui';
import { ModalQueueDetails } from '../../../../Containers/Hermes/modal/queueDetails';
import { compareConsultants } from '../../../../scripts/utils/hermes-helpers';
import { ModalQueueDelete } from '../../../../Containers/Hermes/modal/queueDelete';
import { ModalQueuePause } from '../../../../Containers/Hermes/modal/queuePause';
import { QueueActionsDelete, QueueActionsPause } from '../../../../Containers/Hermes/actions/createActions';
import BlockUi from 'react-block-ui';


const HermesQueuesList = ({ns, history}) => {
  // State variables
  const [queuesCompleted, setQueuesCompleted]: any = useState(null)
  const [postponeModalOpen, setPostponeModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(true);
  const [info, setInfo] = useState({ "_id": "", "enabled": false, "excluded": false });
  const [domainsHelper, setDomainsHelper] = useState({});
  const [config, setConfig] = useState({
    ...MuiConfig,
    ...translationsConfig,
  });

  // Toast messages
  const [show, setShow] = useState(false)
  const [headerMessageTost, setHeaderMessageToast] = useState('')
  const [bodyMessageTost, setBodyMessageToast] = useState('')
  const [colorTost, seColorToast] = useState('')

  useEffect(()=> {
    completeQueues()
    getDomains().then((domains) => {
      setDomainsHelper(domains);
      setConfig({
        ...config,
        fields: { ...getFieldsQueryBuilder(domains, segmentationsHermesMeta) },
      });
    });
  }, [])


  async function completeQueues() {
    try {
      const users = await getUsers()
      const queues = await getQueues()
      const completed = compareConsultants(queues, users)
      setQueuesCompleted(completed.filter(queue => queue.excluded === false))   
      setIsLoading(false)   
    } catch (err) {
      console.log('Error:',err);
      showResultToast('Criação de Filas - Erro!', "Erro ao buscar os usuários e as filas" , 'danger')
      setIsLoading(false)   
    }
  }


  async function getUsers() {
    const res = await getHermesUsers();
    return res;
  }


  async function getQueues() {
    const res = await getHermesQueues();
    return res;                          
  }

  /*** Show result toast*/
  function showResultToast(header, message, color) {
    setHeaderMessageToast(header)
    setBodyMessageToast(message)
    seColorToast(color)
    setShow(true)
  }

  /*** Handle close toast*/
  function handleClose() {
    setShow(false)
  }

   /*** Open postpone modal*/
  function handlePostponeModalOpen() {
    setPostponeModalOpen(true);
  }

  /*** Close postpone modal*/
  function handlePostponeModalClose() {
    setPostponeModalOpen(false);
  }

  /*** Open delete modal*/
  function handleDeleteModalOpen() {
    setDeleteModalOpen(true);
  }

  /*** Close delete modal*/
  function handleDeleteModalClose() {
    setDeleteModalOpen(false);
  }

  /*** Open details modal*/
  function handleDetailsModalOpen(row) {
    setInfo(row);
    setDetailsModalOpen(true);
  }

  /*** Close details modal*/
  function handleDetailsModalClose() {
    setDetailsModalOpen(false);
  }

  /*** Navigate to create queue*/
  function navigateToHermesCreateQueue () {
    let newPath = "/hermes/queue/"
    history.push(newPath)
  }

  /*** Navigate to edit queue*/
  function navigateToHermesEditQueue (queueId: string) {
    let newPath = "/hermes/queue/" + queueId
    history.push(newPath)
  }

  /*** Erase ticket checkbox handler*/
  function handleChangeCheckbox(value: boolean): void {
    setChecked(value)
  }

  function triggerAction(triggerType: string) {
    changeQueue(triggerType, info)
  }

  async function changeQueue(triggerType: string, row) {
    try {
      setIsLoading(true)
      const params = triggerType === "delete" ? "excluded" : triggerType === "start" ? "enabled" : "disabled";
      const result = await handleAuctionQueues(row._id, params, checked)

      if (result) {
        if (triggerType === "delete") {
          handleDeleteModalClose();
          showResultToast('Criação de Filas', 'A fila foi excluída.', 'success');
          row.excluded = true
        } else {
          handlePostponeModalClose();
          if (row.enabled) {
            showResultToast('Criação de Filas', 'A fila foi parada.', 'success')
          } else {
            showResultToast('Criação de Filas', 'A fila foi reiniciada.', 'success')
          }
          row.enabled = !row.enabled
        }
      }

      setInfo(row)
      setChecked(false)
      await completeQueues()
    } catch (err) {
      if (triggerType === "delete") {
        handleDeleteModalClose();
      } else {
        handlePostponeModalClose();
      }

      let error: $TsFixMe = err
      showResultToast('Criação de Filas - Erro!', error?.response, 'danger')
      setIsLoading(false)
    }
  }

  function actionsColumn(cell, row) {
    if (get(row, "enabled") === true) {
      return (
        <QueueActionsPause 
          isLoading={isLoading} 
          handleDetailsModalOpen={handleDetailsModalOpen} 
          handlePostponeModalOpen={handlePostponeModalOpen} 
          navigateToHermesEditQueue={navigateToHermesEditQueue} 
          row={row} 
          setInfo={setInfo}
        />
      )
    } else {
      return (
        <QueueActionsDelete 
          isLoading={isLoading} 
          handleDetailsModalOpen={handleDetailsModalOpen} 
          handleDeleteModalOpen={handleDeleteModalOpen} 
          navigateToHermesEditQueue={navigateToHermesEditQueue} 
          row={row} 
          setInfo={setInfo} 
          triggerAction={changeQueue}
        />
      )
    }
  }

  return (
    <>
      <FzCard>
        <FzCard.Heading fzStyle={{}}>
          <FzSplitLine>
            <FzSplitLine.Left>
              <FzCard.Title componentClass="h3">Criação de Filas&nbsp;</FzCard.Title>
            </FzSplitLine.Left>
            <FzSplitLine.Right>
              <FzButton 
                fzStyle="regular" 
                onClick={() => {navigateToHermesCreateQueue()}}
              >
                Adicionar
              </FzButton> 
            </FzSplitLine.Right>
          </FzSplitLine>
        </FzCard.Heading>
        <BlockUi tag="div" blocking={isLoading} message={"Carregando"}>
          <FzCard.Body>
            <FzTable
              data={queuesCompleted || []}
              metas={QueueMeta}
              ns={ns}
              customColumnFormatters={{
                id: (cell, row) => actionsColumn(cell, row),
                linked: (cell, row) => <div>{row.linked ? "Sim" : "Não"}</div>
              }}
              visibleColumns={["id", "queueName", "delay", "cadencia", "linked"]}
              exportDownload={false}
              clipboard={false}
            /> 
        </FzCard.Body>
        </BlockUi>
      </FzCard>
      <FzToast
        fzStyle={colorTost}
        close={handleClose}
        show={show}
        delay={5000}
        headerMessageTost={headerMessageTost}
        bodyMessageTost={bodyMessageTost}
        autoHide={false}
      />
      <ModalQueueDetails 
        showModal={detailsModalOpen} 
        hideModal={handleDetailsModalClose} 
        infoModal={info}
      />
      <ModalQueueDelete 
        deleteModalOpen={deleteModalOpen} 
        handleDeleteModalClose={handleDeleteModalClose} 
        deleteQ={triggerAction}
        isLoading={isLoading}
      />
      <ModalQueuePause 
        postponeModalOpen={postponeModalOpen} 
        handlePostponeModalClose={handlePostponeModalClose} 
        checked={checked} 
        handleChangeCheckbox={handleChangeCheckbox} 
        pauseQ={triggerAction}
        isLoading={isLoading}
      />
    </>
  )
}

export { HermesQueuesList }