import React, { useEffect, useState } from 'react'
import { setTTL } from '../../../../business/misc-functions';
import { getFzScoreStats } from '../../../../services/api/stats'
import { FzNavSelect } from '../../../fz/nav';
import { PERIOD_DAY_WEEK_MONTH } from '../../../../api/constants';
import { FzCard } from '../../../fz/layout/index';
import { StatsDetailHeader } from "../../../../statistics/stats-components";
import { FzPivotTable } from "../../../fz/grid/pivot-table";
import { pivotMeta } from "../../../../biz/metadatas/funnel";
import { fzscore_meta } from "../../../../biz/metadatas/fzscore-metadata";
import { buildPeriodFilters, buildSupplierFilters } from "../../../../components/table-standard";
import qs from 'qs';

export function FzScoreStats (props) {
  const [requestState, setRequestState] = useState('idle')
  const [currProduct, setCurrProduct] = useState('CP')
  const [currPivot, setCurrPivot ] = useState('period')
  const [filters, setFilters]: any = useState({
    currPeriod: null,
    specificPeriod: null,
  })
  const { ns } = props

  useEffect(() => {
    setUrlQueryString()
  }, [filters])

  useEffect(() => {
    getQueryStringFromUrl()
  },[])

  function setUrlQueryString() {
    if(filters.currPeriod) {
      const query = qs.stringify({...filters}, { addQueryPrefix: true })
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
      history.pushState({path:newurl},'',newurl);
    }
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params: any = Object.fromEntries(urlSearchParams.entries());
    if(params.currPeriod) {
      setFilters({...filters, ...params})
      getStatistics(params.currPeriod)
    }else {
      setFilters({...filters, currPeriod: 'day'})
      getStatistics('day')
    }
  }

  function getStatistics(period) {
    setRequestState('requesting')
    props.toggleBlocking(() => {
      getFzScoreStats(period)
        .then((data) => {
          ns.set("fzscore", data, setTTL(5))
          ns.set("filter_periods_" + currProduct, buildPeriodFilters(data.statistics), null)
          ns.set("filter_suppliers_" + currProduct, buildSupplierFilters(data.statistics), null)
        })
        .catch((err) => {
          console.warn("API ERROR", err)
        }).finally(() => {
          setRequestState('idle')
          props.toggleBlocking()
        })
    })
  }

  function handleChangePeriod (value) {
    setFilters({...filters, specificPeriod: value})
  }

  function handleSelect(periodo) {
    setFilters({...filters, currPeriod: periodo, specificPeriod: null})
    getStatistics(periodo)
  }

  let data = ns.get("fzscore");

  if (!data && requestState === "requesting") {
    return null
  }

  let filterValues = ns.get("filter_periods_" + currProduct)
  if (!filterValues) {
    return null
  }

  let selectedPeriod = filters.specificPeriod || Object.keys(filterValues || { "": "" })[0]

  return (
    <FzCard>
        <FzCard.Heading fzStyle={{}}>
          <FzCard.Title>
            <StatsDetailHeader
              stats={data} title="FinanZero Score Report"
              periods={filterValues} currPeriod={selectedPeriod}
              onChange={handleChangePeriod}
            />
          </FzCard.Title>
        </FzCard.Heading>
        <FzCard.Body>
          <FzNavSelect
            fzStyle={{marginLeft: 0}}
            selected={filters.currPeriod}
            onChange={handleSelect}
            options={[
              { "code": "day", "description": "Daily" },
              { "code": "week", "description": "Weekly" },
              { "code": "month", "description": "Monthly" },
            ]}
          />
          <div className={'mt-3'}>
            <FzPivotTable
              data={data.statistics}
              ns={ns}
              metas={fzscore_meta}
              pivotMetas={pivotMeta}
              context={"fzscore_" + currProduct}
              filterColumnName={currPivot}
              filterValue={selectedPeriod}
              pivotColumnName={"supplier"}
            />
          </div>
        </FzCard.Body>
      </FzCard>
  )
}
