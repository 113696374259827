import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { QueueDetailsContainer } from '../../../../Containers/Hermes/queues/QueueDetailsContainer'
import { FzToast } from '../../../fz/form/notification/toast'
import { FzCard, FzSplitLine } from '../../../fz/layout/index'
import { QueueDetailsMeta } from '../../../../Containers/Hermes/queues/detailsMeta'
import { getAllSuppliers } from '../../../../api/suppliers'
import { getAllProducts } from '../../../../api/applications'
import { getHermesQueueById, postHermesQueue, updateHermesQueue } from '../../../../services/api/hermes'
import { validateAppField } from '../../../../biz/validate-application'
import get from 'lodash/get';

const QueueDetails = ({ ns, history }) => {
  const [isEditing, setIsEditing] = useState(false)
  const { queueId } = useParams<{ queueId: string }>()

  //toast messages
  const [show, setShow] = useState(false)
  const [headerMessageTost, setHeaderMessageTost] = useState('')
  const [bodyMessageTost, setBodyMessageTost] = useState('')
  const [colorTost, setColorTost] = useState('')
  const [delayFormat, setDelayFormat] = useState('segundos')
  const [meta, setMeta]: $TsFixMe = useState(null)
  const [suppliersRawList, setSuppliersRawList]: $TsFixMe = useState(null)
  const [apiLoading, setApiLoading] = useState(false)

  useEffect(() => {
    if (queueId) {
      setIsEditing(true)
      loadQueue(queueId)
    } else {
      ns.saveChange('currentQueue', {suppliers: [{supplier: null, rule: null, branches: [""]}], jsonLogic: {}})
      buildSuppliersAndProductsList()
    }
  }, [])

  useEffect(() => {
    const queue = ns.getChanged('currentQueue')
    if(queue && queue.suppliers && queue.suppliers[0].supplier){
      setBranchOptions(queue.suppliers[0].supplier)
    }
  }, [suppliersRawList])

  function setMetaOptions(products, suppliers) {
    let processedMeta = QueueDetailsMeta
    if(processedMeta.products && processedMeta.products.inputProps){
      processedMeta.products.inputProps.options = products
    }
    if(processedMeta.suppliers && processedMeta.suppliers.inputProps){
      processedMeta.suppliers.inputProps.options = suppliers
    }

    setMeta(processedMeta)
  }

  async function buildSuppliersAndProductsList() {
    setApiLoading(true)
    try {
      const productsResp = await getAllProducts()
      const suppliers = await getAllSuppliers()
      setSuppliersRawList(suppliers)

      let suppliersList: $TsFixMe = []
      for (var supplier of suppliers) {
        suppliersList.push({ code: supplier.internalName, description: supplier.name })
      }
      const productsList = productsResp.map(it => ({
        code: it.internalName,
        description: it.description
      }))
      setMetaOptions(productsList, suppliersList)
    }catch(err) {
      console.warn("API ERROR", err)
      showErrorToast('Erro', 'Erro ao carregar parceiros e produtos.')
    }finally{
      setApiLoading(false)
    }
  }

  function handleClose() {
    setShow(false)
  }

  function inputCadencia(array) {
    return array.join('; ')
  }

  function outputCadencia(cadenciaString) {
    return cadenciaString.replace(/[^\d|;]|;+$|^;+|;{2,}/g, '').split(';').map(Number)
  }

  function processQueueData(data) {
    let processedData = JSON.parse(JSON.stringify(data))
    processedData.cadencia = outputCadencia(data.cadencia)
    processedData.delay = formatDelay(delayFormat, 'segundos', processedData.delay)
    return processedData
  }

  function formatDelay(input, output, value) {
    let inputValue

    switch (input) {
        case 'minutos':
          inputValue = value * 60
          break
        case 'horas':
          inputValue = (value * 60) * 60
          break
        default:
          inputValue = value
    }

    switch (output) {
      case 'minutos':
        return Math.floor(inputValue / 60)
      case 'horas':
        return Math.floor(inputValue / 3600)
      default:
        return Math.floor(inputValue)
    }
    
  }

  function changeDelayFormat(formatSelected){
    const { delay } = ns.getChanged('currentQueue')
    const formattedValue = formatDelay(delayFormat, formatSelected, delay)
    setDelayFormat(formatSelected)
    ns.saveChange('currentQueue.delay', formattedValue)
  }

  function addNewBranch() {
    const { branches } = ns.getChanged('currentQueue.suppliers')[0]
    branches.push("")
    ns.saveChange('currentQueue.suppliers[0].branches', branches)
  }

  async function loadQueue(queueId) {
    setApiLoading(true)
    try{
      const res = await getHermesQueueById(queueId)
      res.cadencia = inputCadencia(res.cadencia)
      ns.saveChange('currentQueue', res)
      buildSuppliersAndProductsList()
    }catch(err){
      showErrorToast('Erro ao carregar a fila', 'Não foi possível carregar a fila.')
    }finally{
      setApiLoading(false)
    }
  }

  async function saveQueue() {
    const errorFields = await checkFormValidation()
    if(errorFields.length > 0) {
      showErrorToast('Erro', 'Preencha todos os campos!')
    }else {
      let dataToSend = processQueueData(ns.getChanged('currentQueue'))
      dataToSend.validade = 1
      dataToSend.orderBy = parseInt(dataToSend.orderBy)
      setApiLoading(true)
      if(isEditing){
        delete dataToSend._id
        try{
          const result = await updateHermesQueue(queueId, dataToSend)
          if(result.status === 200) {
            showSuccessToast()
          }
        }catch(e){
          showErrorToast()
        }finally{
          setApiLoading(false)
        }
      }else {
        dataToSend.enabled = true
        dataToSend.excluded = false
        try{
          const result = await postHermesQueue(dataToSend)
          if(result.status === 200) {
            showSuccessToast()
            setTimeout(()=> {
              history.push('/hermes/queues')
            }, 2000)
          }
        }catch(e){
          showErrorToast()
          setApiLoading(false)
        }
      }
    }
  }

  function showSuccessToast() {
    setColorTost('success')
    setHeaderMessageTost('Fila salva')
    setBodyMessageTost('Fila salva com sucesso.')
    setShow(true)
  }

  function showErrorToast(title = 'Erro ao salvar a fila', message = 'Não foi possível salvar a fila.') {
    setColorTost('danger')
    setHeaderMessageTost(title)
    setBodyMessageTost(message)
    setShow(true)
  }

  function clearBranches(supplierSelected) {
    ns.saveChange('currentQueue.suppliers[0].branches', [''])
    setBranchOptions(supplierSelected)
  }

  function clearBranchesByRuleChange(ruleSelected) {
    const supplierSelected = ns.getChanged('currentQueue.suppliers[0].supplier')
    if(supplierSelected){
      ns.saveChange('currentQueue.suppliers[0].branches', [''])
      setBranchOptions(supplierSelected, ruleSelected)
    }
  }

  function removeBranch(index) {
    const branchesArray = ns.getChanged('currentQueue.suppliers[0].branches')
    branchesArray.splice(index, 1)
    ns.saveChange('currentQueue.suppliers[0].branches', branchesArray)
  }

  function setBranchOptions(supplierSelected, ruleSelected = null) {
    if(suppliersRawList){
      const rule = ruleSelected || ns.getChanged('currentQueue.suppliers[0].rule')
      const newMeta = QueueDetailsMeta
      if(supplierSelected) {
        let branches 
        let options: $TsFixMe = []
        suppliersRawList.map(it => {
          if(it.internalName === supplierSelected) {
            branches = it.branches
          }
        })
        if(rule !== 'exclude') {
          options.push({code: 'all', description: 'Todas'})
        }
        for(let key in branches) {
          options.push({code: key, description: branches[key].branchNick.trim()})
        }
        options.sort((a, b) => a.description.localeCompare(b.description));

        if(newMeta.branch.inputProps){
          newMeta.branch.inputProps.options = options
          setMeta(newMeta)
        }
      }
    }
  }

  async function checkFormValidation() {
    const formItems = Object.keys(meta)
    formItems.splice(formItems.indexOf('branch'), 1) //removing branches model metadata from validation
    const data = ns.getChanged()
    const checkIsEmptyFields = await Promise.all(
      formItems.map(item => {
        const path = meta[item].path
        return validateAppField({ 
          value: get(data, path), 
          data, 
          ns, 
          metaField: meta[item],
        })
        
      })
    )

    const invalidFields = checkIsEmptyFields
    .filter(({ valid }) => !valid)
    .map(({ path }) => path)
    
    const branches = data.currentQueue.suppliers[0].branches
    let idx = branches.indexOf("");
    if(idx > -1 && data.currentQueue.suppliers[0].supplier != null){
      invalidFields.push(`currentQueue.suppliers[0].branches[${idx}]`);
    }
    if(!data.currentQueue.events || !data.currentQueue.events[0]){
      invalidFields.push(`currentQueue.events[0]`);
    }
    ns.set("INVALID", invalidFields)
    return invalidFields
  }

  return (
    <>
      <div style={{ padding: '20px' }}>
        <FzCard>
          <FzCard.Heading fzStyle={{}}>
            <FzSplitLine>
              <FzSplitLine.Left>
                <FzCard.Title componentClass="h3">
                  {isEditing ? 'Edição de Fila' : 'Criação de Fila'}
                </FzCard.Title>
              </FzSplitLine.Left>
            </FzSplitLine>
          </FzCard.Heading>
          <FzCard.Body>
            <QueueDetailsContainer
              ns={ns}
              history={history}
              data={ns.getChanged()}
              saveQueue={saveQueue}
              changeDelayFormat={changeDelayFormat}
              meta={meta}
              addNewBranch={addNewBranch}
              clearBranches={clearBranches}
              clearBranchesByRuleChange={clearBranchesByRuleChange}
              removeBranch={removeBranch}
              loading={apiLoading}
            />
          </FzCard.Body>
        </FzCard>
      </div>
      <FzToast
        fzStyle={colorTost}
        close={handleClose}
        show={show}
        delay={5000}
        headerMessageTost={headerMessageTost}
        bodyMessageTost={bodyMessageTost}
        autoHide={false}
      />
    </>
  )
}

export { QueueDetails }
