import { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { get as getLodash } from 'lodash';
import { Nav, Navbar } from "react-bootstrap";
import { FzCard } from "../../../fz/layout";
import { TicketsAttendanceReport } from "../../../../Containers/Hermes/reports/TicketsAttendance";
import { getTicketsReport } from "../../../../services/api/hermes";
import { salesSummaries } from '../../../../biz/metadatas/sales-metadata'
import moment from "moment";
import qs from 'qs'
import { getSummariesHermesTicketReport } from '../../../../services/api/stats'
import { Summaries } from '../../../../Containers/Hermes/summaries'
import { get, last } from 'lodash'
import { AcceptedBy } from "../../statistics/accepted-by";
export const HermesReports = (props) => {
  const {ns} = props

  const [loading, setLoading] : any = useState(false)
  const [tab, setTab] = useState(props.match.params.tab)
  const [data, setData] : any = useState([])
  const [period, setPeriod] : any = useState({
    from: moment().subtract(1, 'days').toISOString(),
    to: moment().toISOString(),
    notInitialized: false
  })
  const [dataSummaries, setDataSummaries] = useState({ })
  const [showTost, setShowTost] = useState(false)
  const [urlChecked, setUrlChecked] : any = useState(false)

  useEffect(() => {
    getQueryStringFromUrl()
  }, [])

  useEffect(() => {
    if(urlChecked){
      loadTicketsReport()
    }
  }, [urlChecked, period])

  function setUrlQueryString() {
    const query = qs.stringify(period, { addQueryPrefix: true })
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + query;
    history.pushState({ path: newurl }, '', newurl);
  }

  function getQueryStringFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let params: any = Object.fromEntries(urlSearchParams.entries());
    if(params.from && params.to){
      if(moment(params.from).isValid() && moment(params.to).isValid()){
        setPeriod(params)
      }
    }
    setPeriod({...period, notInitialized: params.notInitialized})
    setUrlChecked(true)
  }
  
  async function loadTicketsReport() {
    setLoading(true)
    try{
      const ticketsData = await getTicketsReport(period.from, period.to, period.notInitialized)
      const parseResponse = ticketsData?.map(ticket => ({
        ...ticket, 
        contacts: { 
          notificationsSent: get(last(get(ticket, ["contacts"], [])), ["notificationsSent"], []),
          moreNotifications: get(last(get(ticket, ["contacts"], [])), "moreNotifications", []),
        }}))

      setData(parseResponse)
      setUrlQueryString()
    }catch(e){
      console.error(e)
    }finally {
      setLoading(false)
    }
  }

  const getSummariesData =  async (period) => {
    try {
      const response = await getSummariesHermesTicketReport(period)
      const statistics = getLodash(response, ['statistics'], []).filter(item => item && !item.closedReason)
      setDataSummaries({...response, statistics })
    } catch (error) {
      setShowTost(true)
    }
  }

  const handleTostClose = () => setShowTost(false)

  const toggleLoading = (state) => {
    setLoading(state)
  }

  const reports = {
    tickets: <TicketsAttendanceReport 
                setPeriod={setPeriod} 
                period={period} 
                data={data} 
                ns={ns}/>,
    summary:  <Summaries
                ns={ns}
                data={dataSummaries}
                handleTostClose={handleTostClose}
                showTost={showTost}
              />,
    acceptedBy: <AcceptedBy ns={ns} metas={salesSummaries} setBlocking={toggleLoading} />
  }

  const handleTab = tab => {
    setTab(tab) 
    if(tab === 'summary') {
      getSummariesData("day")
    }
  }

  
  return (
    <BlockUi tag="div" blocking={loading} >
        <FzCard>
          <FzCard.Heading fzStyle={{ paddingBottom: "0", marginBottom: "0" }}>
            <FzCard.Title fzStyle={{ marginBottom: "0" }}>
              <Navbar className="report-navbar">
                <Nav className="adjusted-nav" variant="tabs" onSelect={handleTab} activeKey={tab} >
                  <Nav.Link eventKey="tickets" title="Tickets Attendance">Tickets Attendance</Nav.Link>
                </Nav>
                <Nav className="adjusted-nav" variant="tabs" onSelect={handleTab} activeKey={tab} >
                  <Nav.Link eventKey="summary" title="Capacity Review (Tickets)">Capacity Review (Tickets)</Nav.Link>
                </Nav>
                <Nav className="adjusted-nav" variant="tabs" onSelect={handleTab} activeKey={tab} >
                  <Nav.Link eventKey="acceptedBy" title="Accepted By">Accepted By</Nav.Link>
                </Nav>
              </Navbar>
            </FzCard.Title>
          </FzCard.Heading>
          <FzCard.Body fzStyle={{ gridColumnStart: "1", gridRow: "1", padding: "0.5rem" }}>
            {reports[tab]}
          </FzCard.Body>
        </FzCard>
      </BlockUi>
  )
}